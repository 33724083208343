export class PageConstant {
  public static readonly PAGE_TITLE: string = 'FSMOne';

  public static readonly LOGIN: string = 'Login';
  public static readonly LOGOUT: string = 'Logout';
  public static readonly TRADE: string = 'Trade';
  public static readonly RSP: string = 'Rsp';
  public static readonly FUNDS: string = 'Funds';
  public static readonly DPMS: string = 'Dpms';
  public static readonly BONDS: string = 'Bonds';
  public static readonly HOME: string = 'Helping Investors Around the World Invest Globally & Profitably';
  public static readonly STOCKS: string = 'Stocks';
  public static readonly ETFS: string = 'ETFs';
  public static readonly ABOUT_US: string = 'About Us';
  public static readonly ACCOUNT_OPENING: string = 'Open an Account with FSMOne';
  public static readonly ACCOUNT_UPDATE: string = 'Account Update';
  public static readonly ACCOUNT_SETTING: string = 'Account Setting';
  public static readonly TRANSACTION_RECORD: string = 'Transaction Record';
  public static readonly NOTICE: string = 'Notice';
  public static readonly HOLDINGS: string = 'Holdings';
  public static readonly CASH_ACCOUNT: string = 'Cash Account';
  public static readonly FAQ: string = 'FAQ';
  public static readonly SUPPORT: string = 'Our Services';
  public static readonly CONTACT_US: string = 'Contact Us';
  public static readonly CONNECT_WITH_FSM: string = 'Connect With FSM';
  public static readonly USER_GUIDE: string = 'User Guide';
  public static readonly TRANSFER_IN: string = 'Transfer In';
  public static readonly CASH_SOLUTION: string = 'Cash Solution';
  public static readonly IMPORTANT_DOCUMENTS: string = 'Important Documents';
  public static readonly ENQUIRY: string = 'Enquiry';
  public static readonly INVEST_INQ_APPOINTMENT: string = 'Investment Inquiries Appointment';
  public static readonly REWARDS_PROGRAMME: string = 'Rewards Programme';
  public static readonly REGULAR_SAVINGS_PLAN: string = 'Regular Savings Plan';
  public static readonly FORMS: string = 'Forms';
  public static readonly MAKING_PAYMENTS: string = 'Making Payments';
  public static readonly WEBCAST: string = 'Webcast';
  public static readonly ARTICLE: string = 'Article';
  public static readonly FEES: string = 'Fees';
  public static readonly PORTFOLIO_STAR_RATING: string = 'Portfolio Star Rating';
  public static readonly PORTFOLIO_REBALANCING: string = 'Portfolio Rebalancing';
  public static readonly PORTFOLIO_ROOKIE: string = 'Portfolio Rookie';
  public static readonly IPO: string = 'IPO';
  public static readonly EVENTS: string = 'Events';
	public static readonly INSIGHT: string = 'Insight';
	public static readonly VIDEO: string = 'Video';
	public static readonly FUND_PICK_METHODOLOGY: string = 'FSMOne FUnd Picks Methodology';
	public static readonly STAR_RATINGS: string = 'Star Ratings';
  public static readonly BUZZ: string = 'FSMOne Buzz';
  public static readonly GLOSSARY: string = 'Glossary';
  public static readonly NOTIFICATION: string = "Notification";
  public static readonly MONTHLY_STATEMENT: string = "Monthly Statement";
  public static readonly FPS_DEPOSIT_QR: string = "Deposit with FPS (QR code)";
  public static readonly DPMS_VALUATION_REPORT: string = "DPMS Valuation Report";
  public static readonly DEPOSIT_WITH_EDDA: string = "Deposit with eDDA";
  public static readonly UPLOAD_PAYMENT_PROOF: string = "Upload Payment Proof";
  public static readonly CASH_TRANSFER: string = "Cash Transfer";
  public static readonly TRANSACTION_DONE: string = "Transaction Done";
  public static readonly WITHDRAWAL: string = "Withdrawal";
  public static readonly EDDA: string = "Electronic Direct Debit Authorization";
  public static readonly DDA_APPLICATION: string = "Direct Debit Authorisation";
  public static readonly FPS_DETAILS: string = "FPS Details";
  public static readonly FPS: string = "FPS";
  public static readonly FX_CONVERSION: string = "FX Conversion";
  public static readonly CA_DOCUMENTS: string = 'CA Documents';
  public static readonly CYBER_SECURITY: string = "Cyber Security";
  public static readonly AUTO_SWEEP: string = "Auto-Sweep";
  public static readonly AUTO_SWEEP_ENROLMENT: string = "Auto-Sweep Enrolment";
  public static readonly AUTO_SWEEP_ACCOUNT_FACTSHEET: string = "Auto-Sweep Account Factsheet";
  public static readonly AUTO_SWEEP_VALUATION_REPORT: string = "Auto-Sweep Valuation Report";
  public static readonly BSM_LIVE: string = "Bondsupermart Live / Bond Express";
}
