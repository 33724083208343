import { HeaderMetaModel } from 'src/app/share/models/header/header.model';
import {AppConstant, RouteConstant, FundsupermartRouteConstant} from 'src/app/constant';
import {genRouterLink} from '@util/route';
import {environment} from '@env';

const ICON_PATH = 'assets/images/header/';

export const headerSiteMap: Array<HeaderMetaModel> = [
	{
		title: 'ABOUT US',
		titleKey: 'fsmone.menu.about.us',
		url: genRouterLink(RouteConstant.ABOUT_US),
		clickable: true
	},
	{
		title: 'OUR SERVICES',
		titleKey: 'fsmone.menu.our.services',
		url: '',
		clickable: true,
		children: [
			{
				title: 'INVESTMENT SERVICES',
				titleKey: 'fsmone.menu.investment.services',
				children: [
					{
						title: 'IPO',
						titleKey: 'fsmone.menu.ipo',
						url: genRouterLink(RouteConstant.BONDS, RouteConstant.EXPLORE, RouteConstant.IPO),
						icon: 'fsmicon-ipo-o'
					},
					{
						title: 'Regular Savings Plan (RSP)',
						titleKey: 'fsmone.menu.rsp',
						url: genRouterLink(RouteConstant.SUPPORT, RouteConstant.INVESTMENT_SERVICES, RouteConstant.SUPPORT_REGULAR_SAVINGS_PLAN),
						icon: 'fsmicon-rsp-o'
					},
					{
						title: 'Bondsupermart Live / Bond Express',
						titleKey: 'fsmone.bsm.live.slash.bond.express',
						url: genRouterLink(RouteConstant.BONDS,RouteConstant.EXPLORE, RouteConstant.BSM_LIVE),
						icon: 'fsmicon-bond-express-o'
					},
					{
						title: 'Margin Financing',
						titleKey: 'fsmone.margin.financing',
						url: genRouterLink(RouteConstant.MARGIN_LANDING_PAGE),
						icon: 'fsmicon-bond-express-o'
					},
				]
			},
			{
				title: 'CASH SOLUTIONS',
				titleKey: 'fsmone.menu.cash.solutions',
				children: [
					{
						title: 'Multi-currencies cash account',
						titleKey: 'fsmone.menu.multi.currencies.cash.account',
						url: genRouterLink(RouteConstant.SUPPORT, RouteConstant.CASH_SOLUTIONS, RouteConstant.CASH_ACCOUNT),
						icon: 'fsmicon-view-recurring-o'
					},
					{
						title: 'Auto-Sweep Account',
						titleKey: 'fsmone.auto.sweep.account',
						url: genRouterLink(RouteConstant.SUPPORT,RouteConstant.CASH_SOLUTIONS, RouteConstant.AUTO_SWEEP),
						icon: 'fsmicon-deposit-o'
					},
					{
						title: 'Interest Rate',
						titleKey: 'fsmone.menu.interest.rate',
						url: genRouterLink(RouteConstant.SUPPORT, RouteConstant.CASH_SOLUTIONS, RouteConstant.CASH_ACCOUNT, RouteConstant.INTEREST_RATE),
						icon: 'fsmicon-interest-reserve-o'
					},
					{
						title: 'FX Conversion',
						titleKey: 'fsmone.menu.fx.conversion',
						url: genRouterLink(RouteConstant.SUPPORT, RouteConstant.CASH_SOLUTIONS, RouteConstant.CASH_ACCOUNT, RouteConstant.FX_CONVERSION),
						icon: 'fsmicon-fx-o'
					},
					{
						title: 'Making Payments',
						titleKey: 'fsmone.menu.general.making.payments',
						url: genRouterLink(RouteConstant.SUPPORT,RouteConstant.CASH_SOLUTIONS, RouteConstant.MAKING_PAYMENT),
						icon: 'fsmicon-deposit-o'
					}
				]
			},
			{
				title: 'PROMOTION & EVENTS',
				titleKey: 'fsmone.menu.promotion.events',
				children: [
					{
						title: 'Buzz',
						titleKey: 'fsmone.buzz',
						url: genRouterLink(RouteConstant.SUPPORT, RouteConstant.PROMO_EVENTS, RouteConstant.BUZZ),
						icon: 'fsmicon-price-tag-o'
					}
				]
			},
			{
				title: 'FEES & CHARGES',
				titleKey: 'fsmone.menu.fees.charges',
				children: [
					{
						title: 'Fee Schedule',
						titleKey: 'fsmone.menu.fee.schedule',
						url: genRouterLink(RouteConstant.SUPPORT, RouteConstant.FEES_CHARGES, RouteConstant.FEE_SCHEDULE),
						icon: 'fsmicon-cash-solution-o'
					},
					{
						title: 'Reward Programme',
						titleKey: 'fsmone.menu.reward.programme',
						url: genRouterLink(RouteConstant.SUPPORT,RouteConstant.FEES_CHARGES,RouteConstant.REWARDS_PROGRAM),
						icon: 'fsmicon-reward-o'
					}
				]
			},
			{
				title: 'CLIENT SUPPORT & CONNECT',
				titleKey: 'fsmone.menu.client.support.connect',
				children: [
					{
						title: 'FAQ',
						titleKey: 'fsmone.menu.faq',
						url: genRouterLink(RouteConstant.SUPPORT,RouteConstant.CLIENT_SUPPORT,RouteConstant.FAQ),
						icon: 'fsmicon-question-circle-o'
					},
					{
						title: 'User Guide',
						titleKey: 'fsmone.menu.user.guide',
						url: genRouterLink(RouteConstant.SUPPORT,RouteConstant.CLIENT_SUPPORT,RouteConstant.USER_GUIDE),
						icon: 'fsmicon-guides-o'
					},
					{
						title: 'Investment Inquiries',
						titleKey: 'fsmone.menu.investment.inquiries',
						url: genRouterLink(RouteConstant.SUPPORT, RouteConstant.CLIENT_SUPPORT, RouteConstant.APPOINTMENT),
						icon: 'fsmicon-ask-question-o'
					},
					{
						title: 'Transfer in',
						titleKey: 'fsmone.menu.transfer.in',
						url: genRouterLink(RouteConstant.SUPPORT,RouteConstant.CLIENT_SUPPORT,RouteConstant.SUPPORT_TRANSFER_IN),
						icon: 'fsmicon-transfer-in-o'
					},
					{
						title: 'Forms',
						titleKey: 'fsmone.menu.forms',
						url: genRouterLink(RouteConstant.SUPPORT,RouteConstant.CLIENT_SUPPORT,RouteConstant.FORMS),
						icon: 'fsmicon-download-form-o'
					},
					{
						title: 'Important Documents',
						titleKey: 'fsmone.menu.important.documents',
						url: genRouterLink(RouteConstant.SUPPORT,RouteConstant.CLIENT_SUPPORT,RouteConstant.IMPORTANT_DOC),
						icon: 'fsmicon-bond-issue-o'
					},
					{
						title: 'Connect with FSM',
						titleKey: 'fsmone.menu.connect.with.fsm',
						url: genRouterLink(RouteConstant.SUPPORT,RouteConstant.CLIENT_SUPPORT,RouteConstant.CONNECT_WITH_FSM),
						icon: 'fsmicon-about-us-o'
					},					
					{
						title: 'Contact Us',
						titleKey: 'fsmone.menu.contact.us',
						url: genRouterLink(RouteConstant.SUPPORT,RouteConstant.CLIENT_SUPPORT,RouteConstant.CONTACT_US),
						icon: 'fsmicon-cse-o'
					}
				]
			}
		]
	},
	{
		title: 'INSIGHT',
		titleKey: 'fsmone.menu.insight',
		url: '',
		clickable: true,
		children: [
			{
				title: 'MARKET TREND',
				titleKey: 'fsmone.menu.market.trend',
				children: [
					{
						title: 'Best/Worst Performing',
						titleKey: 'fsmone.menu.best.worst.performing',
						url: genRouterLink(RouteConstant.FUNDS,RouteConstant.MARKET_TREND,RouteConstant.BEST_AND_WORST_PERFORMERS),
						icon: 'fsmicon-gainer-o'
					},
					{
						title: 'Highest Dividend Paying Fund',
						titleKey: 'fsmone.menu.highest.dividend.paying.fund',
						url: genRouterLink(RouteConstant.FUNDS,RouteConstant.MARKET_TREND,RouteConstant.HIGHEST_DIVIDEND_YIELD),
						icon: 'fsmicon-holder-put-o'
					},
					{
						title: 'Top Volume',
						titleKey: 'fsmone.menu.top.volume',
						url: genRouterLink(RouteConstant.INSIGHT,RouteConstant.MARKET_TREND,RouteConstant.TOP_VOLUME),
						icon: 'fsmicon-indices-o'
					}				
				]
			},
			{
				title: 'FSMONE RESEARCH AND IDEAS',
				titleKey: 'fsmone.menu.fsm.research.and.ideas',
				children: [
					{
						title: 'FSMOne Fund Picks',
						titleKey: 'fsmone.menu.fund.picks',
						url: genRouterLink(RouteConstant.INSIGHT,RouteConstant.RESEARCH,RouteConstant.FUND_PICKS),
						icon: 'fsmicon-fund-hub-o'
					},
					{
						title: 'FSMOne Star Ratings',
						titleKey: 'fsmone.menu.star.ratings',
						url: genRouterLink(RouteConstant.INSIGHT,RouteConstant.RESEARCH,RouteConstant.STAR_RATING),
						icon: 'fsmicon-star-rating'
					},
					{
						title: 'Product Research',
						titleKey: 'fsmone.menu.product.research',
						url: genRouterLink(RouteConstant.INSIGHT,RouteConstant.RESEARCH,RouteConstant.FSMONE_RESEARCH_INSIGHT),
						icon: 'fsmicon-market-depth-o'
					},
					{
						title: 'Videos',
						titleKey: 'fsmone.menu.videos',
						url: genRouterLink(RouteConstant.INSIGHT,RouteConstant.RESEARCH,RouteConstant.VIDEOS),
						icon: 'fsmicon-webinars-video-o'
					}
				]
			},
			{
				title: 'INVEST 101',
				titleKey: 'fsmone.menu.invest.101',
				children: [
					{
						title: 'Glossary',
						titleKey: 'fsmone.menu.glossary',
						url: genRouterLink(RouteConstant.INSIGHT,RouteConstant.INVEST_101,RouteConstant.GLOSSARY),
						icon: 'fsmicon-selector'
					},
					{
						title: 'How to Invest in...',
						titleKey: 'fsmone.menu.how.to.invest.in.',
						url: genRouterLink(RouteConstant.INSIGHT,RouteConstant.INVEST_101,RouteConstant.BASIC_101),
						icon: 'fsmicon-question-circle-o'
					}
				]
			},
			{
				title: 'MARKET DATA',
				titleKey: 'fsmone.menu.market.data',
				children: [
					{
						title: 'FSMOne Indices',
						titleKey: 'fsmone.menu.indices',
						url: genRouterLink(RouteConstant.INSIGHT, RouteConstant.MARKET_DATA, RouteConstant.MARKET_INDICES, RouteConstant.FSMONE_INDICES),
						icon: 'fsmicon-indices-o'
					},
					{
						title: 'Market Indices',
						titleKey: 'fsmone.menu.market.indices',
						url: genRouterLink(RouteConstant.INSIGHT, RouteConstant.MARKET_DATA, RouteConstant.MARKET_INDICES, RouteConstant.MAJOR_INDICES),
						icon: 'fsmicon-indices-o'
					},
					{
						title: 'Market PE and Earnings',
						titleKey: 'fsmone.menu.market.pe.earnings',
						url: genRouterLink(RouteConstant.INSIGHT,RouteConstant.MARKET_DATA,RouteConstant.MARKET_INDICES, RouteConstant.MARKET_PE_EARNINGS_GROWTH),
						icon: 'fsmicon-profit-loss'
					},
					{
						title: 'Sovereign Bonds',
						titleKey: 'fsmone.bonds.intro.topic1.list5',
						url: genRouterLink(RouteConstant.BONDS,RouteConstant.MARKET_TREND,RouteConstant.SOVEREIGN_BONDS),
						icon: 'fsmicon-yieldchart-o'
					}
				]
			},
			{
				title: 'TOOLS',
				titleKey: 'fsmone.menu.tool',
				children: [
					{
						title: 'Calculator',
						titleKey: 'fsmone.menu.calculator',
						url: genRouterLink(RouteConstant.BONDS,RouteConstant.TOOLS,RouteConstant.BOND_CALCULATOR),
						icon: 'fsmicon-calculator-o'
					},
					{
						title: 'Chart Centre',
						titleKey: 'fsmone.menu.chart.centre',
						url: genRouterLink(RouteConstant.FUNDS,RouteConstant.TOOLS,RouteConstant.CHART_CENTRE),
						icon: 'fsmicon-chart-analysis-o'
					},
					{
						title: 'Selector',
						titleKey: 'fsmone.menu.selector',
						url: genRouterLink(RouteConstant.FUNDS,RouteConstant.TOOLS,RouteConstant.FUND_SELECTOR),
						icon: 'fsmicon-selector'
					}
				]
			}
		]
	},
	{
		title: 'FUNDS',
		titleKey: 'fsmone.menu.funds',
		url: genRouterLink(RouteConstant.FUNDS),
		clickable: true,
		children: [
			{
				title: 'EXPLORE FUNDS',
				titleKey: 'fsmone.menu.explore.fund',
				children: [
					{
						title: 'Fund Homepage',
						titleKey: 'fsmone.menu.fund.homepage',
						url: genRouterLink(RouteConstant.FUNDS),
						icon: 'fsmicon-fund-home-o'
					},
					{
						title: 'Fund School',
						titleKey: 'fsmone.menu.fund.school',
						url: genRouterLink(RouteConstant.FUNDS,RouteConstant.EXPLORE,RouteConstant.FUND_101),
						icon: 'fsmicon-fund-manager-o'
					},
					{
						title: 'Fund House Insight',
						titleKey: 'fund.houses.insights',
						url: genRouterLink(RouteConstant.FUNDS,RouteConstant.EXPLORE,RouteConstant.FUND_HOUSE_INSIGHT),
						icon: 'fsmicon-ideas-o'
					},
					{
						title: 'FSMOne Fund Picks',
						titleKey: 'fsmone.menu.fund.picks',
						url: genRouterLink(RouteConstant.FUNDS,RouteConstant.EXPLORE,RouteConstant.FUND_PICKS),
						icon: 'fsmicon-fund-hub-o'
					},
					{
						title: 'FSMOne Research Insight',
						titleKey: 'fsmone.menu.research.in.sight',
						url: genRouterLink(RouteConstant.FUNDS,RouteConstant.EXPLORE,RouteConstant.FSMONE_RESEARCH_INSIGHT),
						icon: 'fsmicon-ideas-o'
					},
					{
						title: 'Fees',
						titleKey: 'fsmone.menu.fees',
						url: genRouterLink(RouteConstant.FUNDS, RouteConstant.EXPLORE, RouteConstant.FEE_SCHEDULE),
						icon: 'fsmicon-fee-o'
					}
				]
			},
			{
				title: 'MARKET TREND',
				titleKey: 'fsmone.menu.market.trend',
				children: [
					{
						title: 'Best / Worst Performing',
						titleKey: 'fsmone.menu.best.worst.performing',
						url: genRouterLink(RouteConstant.FUNDS,RouteConstant.MARKET_TREND,RouteConstant.BEST_AND_WORST_PERFORMERS),
						icon: 'fsmicon-gainer-o'
					},
					{
						title: 'FSMOne Indicies',
						titleKey: 'fsmone.menu.indices',
						url: genRouterLink(RouteConstant.FUNDS,RouteConstant.MARKET_TREND,RouteConstant.MARKET_INDICES, RouteConstant.FSMONE_INDICES),
						icon: 'fsmicon-indices-o'
					},
					{
						title: 'Highest Dividend Yield',
						titleKey: 'fsmone.menu.highest.dividend.yield',
						url: genRouterLink(RouteConstant.FUNDS,RouteConstant.MARKET_TREND,RouteConstant.HIGHEST_DIVIDEND_YIELD),
						icon: 'fsmicon-yield'
					},
					{
						title: 'Market Indices',
						titleKey: 'fsmone.menu.market.indices',
						url: genRouterLink(RouteConstant.FUNDS,RouteConstant.MARKET_TREND,RouteConstant.MARKET_INDICES),
						icon: 'fsmicon-indices-o'
					}
				]
			},
			{
				title: 'TOOLS',
				titleKey: 'fsmone.menu.tool',
				children: [
					{
						title: 'Chart Centre',
						titleKey: 'fsmone.menu.chart.centre',
						url: genRouterLink(RouteConstant.FUNDS,RouteConstant.TOOLS,RouteConstant.CHART_CENTRE),
						icon: 'fsmicon-chart-centre-o'
					},
					{
						title: 'Fund Selector',
						titleKey: 'fsmone.menu.fund.selector',
						url: genRouterLink(RouteConstant.FUNDS,RouteConstant.TOOLS,RouteConstant.FUND_SELECTOR),
						icon: 'fsmicon-selector-o'
					},
					{
						title: 'Fund Restriction',
						titleKey: 'fsmone.menu.fund.restriction',
						url: genRouterLink(RouteConstant.FUNDS,RouteConstant.TOOLS,RouteConstant.FUND_RESTRICTION),
						icon: 'fsmicon-restricted-Prod-o'
					}
				]
			}
			,
			{
				title: 'FUND INFORMATION',
				titleKey: 'fsmone.menu.fund.information',
				children: [
					{
						title: 'Fund Holiday',
						titleKey: 'fsmone.menu.fund.holiday',
						url: genRouterLink(RouteConstant.FUNDS,RouteConstant.FUND_INFO,RouteConstant.FUND_CALENDAR),
						icon: 'fsmicon-calendar-o'
					},
					{
						title: 'Fund Managers',
						titleKey: 'fsmone.menu.fund.managers',
						url: genRouterLink(RouteConstant.FUNDS,RouteConstant.FUND_INFO,RouteConstant.FUND_HOUSE),
						icon: 'fsmicon-fund-manager-o'
					},
					{
						title: 'New Fund on Board',
						titleKey: 'fsmone.menu.new.fund.on.board',
						url: genRouterLink(RouteConstant.FUNDS,RouteConstant.FUND_INFO,RouteConstant.NEW_FUNDS_ON_BOARD),
						icon: 'fsmicon-new-fund'
					},
					{
						title: 'Switching Lag Time',
						titleKey: 'fsmone.menu.switching.lag.time',
						url: genRouterLink(RouteConstant.FUNDS,RouteConstant.FUND_INFO,RouteConstant.FUND_SWITCH_LAG_TIME),
						icon: 'fsmicon-switch'
					}
				]
			}
		]
	},
	{
		title: 'BONDS',
		titleKey: 'fsmone.menu.bonds',
		url: genRouterLink(RouteConstant.BONDS),
		clickable: true,
		children: [
			{
				title: 'EXPLORE BONDS',
				titleKey: 'fsmone.menu.explore.bond',
				children: [
					{
						title: 'Bond Homepage',
						titleKey: 'fsmone.menu.bond.homepage',
						url: genRouterLink(RouteConstant.BONDS),
						icon: 'fsmicon-bond-home-o'
					},
					{
						title: 'Bond School',
						titleKey: 'fsmone.article.category.bond.school',
						url: genRouterLink(RouteConstant.BONDS,RouteConstant.EXPLORE,RouteConstant.BOND_101),
						icon: 'fsmicon-school-o'
					},
					{
						title: 'Bondsupermart Live / Bond Express',
						titleKey: 'fsmone.bsm.live.slash.bond.express',
						url: genRouterLink(RouteConstant.BONDS,RouteConstant.EXPLORE,RouteConstant.BSM_LIVE),
						icon: 'fsmicon-bond-express-o'
					},
					{
						title: 'FSMOne Research Insight',
						titleKey: 'fsmone.menu.research.in.sight',
						url: genRouterLink(RouteConstant.BONDS,RouteConstant.EXPLORE, RouteConstant.FSMONE_RESEARCH_INSIGHT),
						icon: 'fsmicon-ideas-o'
					},
					{
						title: 'Newly Issued Bonds',
						titleKey: 'fsmone.menu.newly.issued.bonds',
						url: genRouterLink(RouteConstant.BONDS, RouteConstant.EXPLORE, RouteConstant.IPO),
						icon: 'fsmicon-ipo-o'
					},
					{
						title: 'Fees',
						titleKey: 'fsmone.menu.fees',
						url: genRouterLink(RouteConstant.BONDS,RouteConstant.EXPLORE, RouteConstant.FEE_SCHEDULE, RouteConstant.BONDS),
						icon: 'fsmicon-fee-o'
					}	,
					{
						title: 'TreasuryNow',
						titleKey: 'fsmone.treasury.now',
						url: genRouterLink(RouteConstant.BONDS,RouteConstant.EXPLORE, RouteConstant.TREASURY_NOW),
						icon: 'fsmicon-bond-express-o'
					}		
				]
			},
			{
				title: 'MARKET TREND',
				titleKey: 'fsmone.menu.market.trend',
				children: [
					{
						title: 'Best/Worst Performing',
						titleKey: 'fsmone.menu.best.worst.performing',
						url: genRouterLink(RouteConstant.BONDS,RouteConstant.MARKET_TREND,RouteConstant.BEST_AND_WORST_PERFORMERS),
						icon: 'fsmicon-gainer-o'
					},
					{
						title: 'Bond Index/Data',
						titleKey: 'fsmone.menu.bond.index.data',
						url: genRouterLink(RouteConstant.BONDS,RouteConstant.MARKET_TREND,RouteConstant.INDEX_DATA),
						icon: 'fsmicon-index-data-o'
					},
					{
						title: 'Sovereign Bonds',
						titleKey: 'fsmone.bonds.intro.topic1.list5',
						url: genRouterLink(RouteConstant.BONDS,RouteConstant.MARKET_TREND,RouteConstant.SOVEREIGN_BONDS),
						icon: 'fsmicon-yield'
					}
				]
			},
			{
				title: 'TOOLS',
				titleKey: 'fsmone.menu.tool',
				children: [
					{
						title: 'Bond Calculator',
						titleKey: 'fsmone.menu.bond.calculator',
						url: genRouterLink(RouteConstant.BONDS,RouteConstant.TOOLS,RouteConstant.BOND_CALCULATOR),
						icon: 'fsmicon-calculator-o'
					},
					{
						title: 'Bond Selector',
						titleKey: 'fsmone.menu.bond.selector',
						url: genRouterLink(RouteConstant.BONDS,RouteConstant.TOOLS,RouteConstant.BOND_SELECTOR),
						icon: 'fsmicon-selector-o'
					},
					{
						title: 'Chart Centre',
						titleKey: 'fsmone.menu.chart.centre',
						url: genRouterLink(RouteConstant.BONDS,RouteConstant.TOOLS,RouteConstant.CHART_CENTRE),
						icon: 'fsmicon-chart-centre-o'
					}					
				]
			}
		]
	},
	{
		title: 'ETFS',
		titleKey: 'fsmone.general.etf',
		url: genRouterLink(RouteConstant.ETFS),
		clickable: true,
		children: [
			{
				title: 'EXPLORE ETFs',
				titleKey: 'fsmone.menu.explore.etf',
				children: [
					{
						title: 'ETF Homepage',
						titleKey: 'fsmone.menu.etf.homepage',
						url: genRouterLink(RouteConstant.ETFS),
						icon: 'fsmicon-etf-home-o'
					},
					{
						title: 'ETFs School',
						titleKey: 'fsmone.menu.etf.school',
						url: genRouterLink(RouteConstant.ETFS,RouteConstant.EXPLORE, RouteConstant.ETF_101),
						icon: 'fsmicon-school-o'
					},
					{
						title: 'FSMOne Research Insight',
						titleKey: 'fsmone.menu.research.in.sight',
						url: genRouterLink(RouteConstant.ETFS,RouteConstant.EXPLORE,RouteConstant.FSMONE_RESEARCH_INSIGHT),
						icon: 'fsmicon-ideas-o'
					},
					{
						title: 'Fees',
						titleKey: 'fsmone.menu.fees',
						url: genRouterLink(RouteConstant.ETFS,RouteConstant.EXPLORE, RouteConstant.FEE_SCHEDULE, RouteConstant.HK_STOCK),
						icon: 'fsmicon-fee-o'
					}
				]
			},
			{
				title: 'MARKET TREND',
				titleKey: 'fsmone.menu.market.trend',
				children: [
					{
						title: 'Best/Worst Performing',
						titleKey: 'fsmone.menu.best.worst.performing',
						url: genRouterLink(RouteConstant.ETFS,RouteConstant.MARKET_TREND,RouteConstant.BEST_AND_WORST_PERFORMERS),
						icon: 'fsmicon-gainer-o'
					},
					{
						title: 'Market Indices',
						titleKey: 'fsmone.menu.market.indices',
						url: genRouterLink(RouteConstant.ETFS,RouteConstant.MARKET_TREND,RouteConstant.MARKET_INDICES),
						icon: 'fsmicon-index-data-o'
					}
				]
			},
			{
				title: 'TOOLS',
				titleKey: 'fsmone.menu.tool',
				children: [
					{
						title: 'Chart Centre',
						titleKey: 'fsmone.menu.chart.centre',
						url: genRouterLink(RouteConstant.ETFS,RouteConstant.TOOLS,RouteConstant.CHART_CENTRE),
						icon: 'fsmicon-chart-centre-o'
					},{
						title: 'ETF Calculator',
						titleKey: 'fsmone.menu.etf.calculator',
						url: genRouterLink(RouteConstant.ETFS,RouteConstant.TOOLS,RouteConstant.ETFS_CALCULATOR),
						icon: 'fsmicon-calculator-o'
					},
					{
						title: 'ETF Selector',
						titleKey: 'fsmone.menu.etf.selector',
						url: genRouterLink(RouteConstant.ETFS,RouteConstant.TOOLS,RouteConstant.ETFS_SELECTOR),
						icon: 'fsmicon-selector-o'
					}
				]
			},
			{
				title: 'ETF INFORMATION',
				titleKey: 'fsmone.menu.etf.information',
				children: [
					{
						title: 'ETF Managers',
						titleKey: 'fsmone.menu.etf.managers',
						url: genRouterLink(RouteConstant.ETFS,RouteConstant.ETF_INFO,RouteConstant.MANAGER_LISTING),
						icon: 'fsmicon-fund-manager-o'
					}
				]
			}
		]
	},
	{
		title: 'STOCKS',
		titleKey: 'fsmone.menu.stocks',
		url: genRouterLink(RouteConstant.STOCKS),
		clickable: true,
		children: [
			{
				title: 'EXPLORE STOCKS',
				titleKey: 'fsmone.menu.explore.stock',
				children: [
					{
						title: 'Stock Homepage',
						titleKey: 'fsmone.menu.stock.homepage',
						url: genRouterLink(RouteConstant.STOCKS),
						icon: 'fsmicon-stock-home-o'
					},
					{
						title: 'Stock School',
						titleKey: 'stock.school',
						url: genRouterLink(RouteConstant.STOCKS,RouteConstant.EXPLORE,RouteConstant.STOCK_101),
						icon: 'fsmicon-legal-o'
					},
					{
						title: 'Stocks IPO',
						titleKey: 'fsmone.menu.stock.ipo',
						url: genRouterLink(RouteConstant.STOCKS,RouteConstant.EXPLORE,RouteConstant.STOCKS_IPO),
						icon: 'fsmicon-ipo-o'
					},
					{
						title: 'FSMOne Research Insight',
						titleKey: 'fsmone.menu.research.in.sight',
						url: genRouterLink(RouteConstant.STOCKS,RouteConstant.EXPLORE,RouteConstant.FSMONE_RESEARCH_INSIGHT),
						icon: 'fsmicon-managed-portfolio-insights-o'
					},
					{
						title: 'Fees',
						titleKey: 'fsmone.menu.fees',
						url: genRouterLink(RouteConstant.STOCKS,RouteConstant.EXPLORE, RouteConstant.FEE_SCHEDULE, RouteConstant.HK_STOCK),
						icon: 'fsmicon-fee-o'
					}
				]
			},
			{
				title: 'MARKET TREND',
				titleKey: 'fsmone.menu.market.trend',
				children: [
					{
						title: 'Best/Worst Performing',
						titleKey: 'fsmone.menu.best.worst.performing',
						url: genRouterLink(RouteConstant.STOCKS,RouteConstant.MARKET_TREND,RouteConstant.BEST_AND_WORST_PERFORMERS),
						icon: 'fsmicon-gainer-o'
					},
					{
						title: 'Market Performances',
						titleKey: 'fsmone.menu.market.performances',
						url: genRouterLink(RouteConstant.STOCKS,RouteConstant.MARKET_TREND,RouteConstant.MARKET_PERFORMANCE_DETAIL),
						icon: 'fsmicon-market-depth-o'
					},
					{
						title: 'Top Turnover',
						titleKey: 'fsmone.menu.stock.top.turnover',
						url: genRouterLink(RouteConstant.STOCKS,RouteConstant.MARKET_TREND,RouteConstant.TOP_TURNOVER),
						icon: 'fsmicon-yieldchart-o'
					}
				]
			},
			{
				title: 'TOOLS',
				titleKey: 'fsmone.menu.tool',
				setTransform: false,
				children: [
					{
						title: 'Stock Calculators',
						titleKey: 'fsmone.menu.stock.calculator',
						url: genRouterLink(RouteConstant.STOCKS,RouteConstant.TOOLS,RouteConstant.STOCKS_CALCULATOR),
						icon: 'fsmicon-calculator-o'
					},
					{
						title: 'Stock Selector',
						titleKey: 'fsmone.menu.stock.selector',
						url: genRouterLink(RouteConstant.STOCKS,RouteConstant.TOOLS,RouteConstant.STOCKS_SELECTOR),
						icon: 'fsmicon-selector-o'
					},
				]
			}
		]
	},
	{
		title: 'HKD 50,000 PORTFOLIO',
		titleKey: 'fsmone.menu.hkd.50000.portfolio',
		url: genRouterLink(RouteConstant.DPMS),
		clickable: true
	},	
	{
		title: 'iFAST TV',
		url: environment.ifastTvUrl,
		clickable: true,
		icon: ICON_PATH + 'confirmed_ifast_tv_logo.svg',
	}
]

export const headerSiteMapWithBanner: Array<HeaderMetaModel> = [
	{
		title: 'ABOUT US',
		titleKey: 'fsmone.menu.about.us',
		url: genRouterLink(RouteConstant.ABOUT_US),
		clickable: true
	},
	{
		title: 'OUR SERVICES',
		titleKey: 'fsmone.menu.our.services',
		url: '',
		clickable: true,
		childrenGrid: [[[0, 1, 2], [5, 6, 7]], [[3, 4],[8]]],
		childrenGridClass: [['our-service-left-wrapper'], ['']],
		children: [
			{
				title: 'INVESTMENT ACCOUNT',
				titleKey: 'investment.account',
				children: [
					{
						title: 'Personal',
						titleKey: 'personal',
						url: genRouterLink(RouteConstant.ACCOUNT_OPENING),
						icon: 'fsmicon-account-o',
						value: 'personal-account'
					},
					{
						title: 'Discretionary',
						titleKey: 'discretionary',
						url: genRouterLink(RouteConstant.ACCOUNT_OPENING),
						icon: 'fsmicon-account-o',
						value: 'discretionary-account'
					},
					{
						title: 'Beneficiary',
						titleKey: 'beneficiary',
						url: genRouterLink(RouteConstant.ACCOUNT_OPENING),
						icon: 'fsmicon-bene-acct-o',
						value: 'beneficiary-account'
					},
					{
						title: 'Corporate',
						titleKey: 'corporate',
						url: genRouterLink(RouteConstant.ACCOUNT_OPENING, RouteConstant.ACCOUNT_OPENING_CORP),
						icon: 'fsmicon-corp-acct-o'
					}
				]
			},
			{
				title: 'INVESTMENT SERVICES',
				titleKey: 'fsmone.menu.investment.services',
				children: [
					{
						title: 'IPO',
						titleKey: 'fsmone.menu.ipo',
						url: genRouterLink(RouteConstant.BONDS, RouteConstant.EXPLORE, RouteConstant.IPO),
						icon: 'fsmicon-ipo-o',
						desc: ['bond', 'stock.trade.stock']
					},
					{
						title: 'Regular Savings Plan (RSP)',
						titleKey: 'fsmone.menu.rsp',
						url: genRouterLink(RouteConstant.SUPPORT, RouteConstant.INVESTMENT_SERVICES, RouteConstant.SUPPORT_REGULAR_SAVINGS_PLAN),
						icon: 'fsmicon-rsp-o',
						desc: ['fsmone.fund', 'fsmone.managed.portfolios']
					},
					{
						title: 'Bondsupermart Live / Bond Express',
						titleKey: 'fsmone.bsm.live.slash.bond.express',
						url: genRouterLink(RouteConstant.BONDS,RouteConstant.EXPLORE,RouteConstant.BSM_LIVE),
						icon: 'fsmicon-bond-express-o',
						desc: ['fsmone.navigation.menu.bond.express.desc']
					},
					{
						title: 'Margin Financing',
						titleKey: 'fsmone.margin.financing',
						url: genRouterLink(RouteConstant.MARGIN_LANDING_PAGE),
						icon: 'fsmicon-bond-express-o'
					}
				]
			},
			{
				title: 'CASH SOLUTIONS',
				titleKey: 'fsmone.menu.cash.solutions',
				children: [
					{
						title: 'Multi-currencies cash account',
						titleKey: 'fsmone.menu.multi.currencies.cash.account',
						url: genRouterLink(RouteConstant.SUPPORT, RouteConstant.CASH_SOLUTIONS, RouteConstant.CASH_ACCOUNT),
						icon: 'fsmicon-view-recurring-o',
						desc: ['hkd', 'usd', 'cny', 'others']
					},
					{
						title: 'Auto-Sweep Account',
						titleKey: 'fsmone.auto.sweep.account',
						url: genRouterLink(RouteConstant.SUPPORT,RouteConstant.CASH_SOLUTIONS, RouteConstant.AUTO_SWEEP),
						icon: 'fsmicon-deposit-o'
					},
					{
						title: 'Interest Rate',
						titleKey: 'fsmone.menu.interest.rate',
						url: genRouterLink(RouteConstant.SUPPORT, RouteConstant.CASH_SOLUTIONS, RouteConstant.CASH_ACCOUNT, RouteConstant.INTEREST_RATE),
						icon: 'fsmicon-interest-reserve-o'
					},
					{
						title: 'FX Conversion',
						titleKey: 'fsmone.menu.fx.conversion',
						url: genRouterLink(RouteConstant.SUPPORT, RouteConstant.CASH_SOLUTIONS, RouteConstant.CASH_ACCOUNT, RouteConstant.FX_CONVERSION),
						icon: 'fsmicon-fx-o',
						desc: ['fsmone.navigation.menu.real.time.conversion.desc']
					},
					{
						title: 'Making Payments',
						titleKey: 'fsmone.menu.general.making.payments',
						url: genRouterLink(RouteConstant.SUPPORT,RouteConstant.CASH_SOLUTIONS, RouteConstant.MAKING_PAYMENT),
						icon: 'fsmicon-deposit-o',
						desc: ['cheque', 'edda.short.form', 'fps', 'others']
					}
				]
			},
			{
				title: 'PROMOTION & EVENTS',
				titleKey: 'fsmone.menu.promotion.events',
				children: [
					{
						title: 'Buzz',
						titleKey: 'fsmone.buzz',
						url: genRouterLink(RouteConstant.SUPPORT, RouteConstant.PROMO_EVENTS, RouteConstant.BUZZ),
						icon: 'fsmicon-price-tag-o'
					}
				]
			},
			{
				title: 'FEES & CHARGES',
				titleKey: 'fsmone.menu.fees.charges',
				children: [
					{
						title: 'Fee Schedule',
						titleKey: 'fsmone.menu.fee.schedule',
						url: genRouterLink(RouteConstant.SUPPORT, RouteConstant.FEES_CHARGES, RouteConstant.FEE_SCHEDULE),
						icon: 'fsmicon-cash-solution-o'
					},
					{
						title: 'Reward Programme',
						titleKey: 'fsmone.menu.reward.programme',
						url: genRouterLink(RouteConstant.SUPPORT,RouteConstant.FEES_CHARGES,RouteConstant.REWARDS_PROGRAM),
						icon: 'fsmicon-reward-o'
					}
				]
			},
			{
				title: 'Trading',
				titleKey: 'trading',
				children: [
					{
						title: 'Fund',
						titleKey: 'fsmone.fund',
						url: genRouterLink(RouteConstant.TRADE, RouteConstant.FUNDS),
						icon: 'fsmicon-fund-home-o',
						postLogin: true
					},
					{
						title: 'Bond',
						titleKey: 'bond',
						url: genRouterLink(RouteConstant.TRADE, RouteConstant.BONDS),
						icon: 'fsmicon-new-bond-o',
						desc: ['fsmone.bond.express'],
						postLogin: true
					},
					{
						title: 'Stock & ETF',
						titleKey: 'fsmone.stock.and.etf',
						url: genRouterLink(RouteConstant.TRADE, RouteConstant.STOCKS),
						icon: ' fsmicon-stock-home-o',
            desc: ['hong.kong', 'fsmone.bond.country.us', 'fsmone.fee.sh.sz.stocks', 'singapore'],
						postLogin: true
					},
					{
						title: 'Managed Portfolios',
						titleKey: 'fsmone.managed.portfolios',
						url: genRouterLink(RouteConstant.TRADE, RouteConstant.MANAGED_PORTFOLIO),
						icon: 'fsmicon-managed-portfolio-insights-o',
						postLogin: true
					}
				]
			},
			{
				title: 'CLIENT SUPPORT & CONNECT',
				titleKey: 'fsmone.menu.client.support.connect',
				mobileMenuClass: 'pb-0',
				children: [
					{
						title: 'FAQ',
						titleKey: 'fsmone.menu.faq',
						url: genRouterLink(RouteConstant.SUPPORT,RouteConstant.CLIENT_SUPPORT,RouteConstant.FAQ),
						icon: 'fsmicon-question-circle-o'
					},
					{
						title: 'User Guide',
						titleKey: 'fsmone.menu.user.guide',
						url: genRouterLink(RouteConstant.SUPPORT,RouteConstant.CLIENT_SUPPORT,RouteConstant.USER_GUIDE),
						icon: 'fsmicon-guides-o'
					},
					{
						title: 'Investment Inquiries',
						titleKey: 'fsmone.menu.investment.inquiries',
						url: genRouterLink(RouteConstant.SUPPORT,RouteConstant.CLIENT_SUPPORT,RouteConstant.APPOINTMENT),
						icon: 'fsmicon-ask-question-o',
						postLogin: true
					},
					{
						title: 'Transfer in',
						titleKey: 'fsmone.menu.transfer.in',
						url: genRouterLink(RouteConstant.SUPPORT,RouteConstant.CLIENT_SUPPORT,RouteConstant.SUPPORT_TRANSFER_IN),
						icon: 'fsmicon-transfer-in-o',
						desc: ['bond', 'fsmone.fund', 'fsmone.stock.and.etf']
					},
					{
						title: 'Forms',
						titleKey: 'fsmone.menu.forms',
						url: genRouterLink(RouteConstant.SUPPORT,RouteConstant.CLIENT_SUPPORT,RouteConstant.FORMS),
						icon: 'fsmicon-download-form-o',
						desc: ['pi.declaration', 'w.8ben']
					}
				]
			},
			{
				title: '',
				titleKey: '',
				mobileMenuClass: 'pt-0',
				children: [
					{
						title: 'Important Documents',
						titleKey: 'fsmone.menu.important.documents',
						url: genRouterLink(RouteConstant.SUPPORT,RouteConstant.CLIENT_SUPPORT,RouteConstant.IMPORTANT_DOC),
						icon: 'fsmicon-bond-issue-o'
					},
					{
						title: 'Connect with FSM',
						titleKey: 'fsmone.menu.connect.with.fsm',
						url: genRouterLink(RouteConstant.SUPPORT,RouteConstant.CLIENT_SUPPORT,RouteConstant.CONNECT_WITH_FSM),
						icon: 'fsmicon-about-us-o',
						desc: ['fsm.mobile.apps', 'fsmone.subscribe.enewsletter']
					},					
					{
						title: 'Contact Us',
						titleKey: 'fsmone.menu.contact.us',
						url: genRouterLink(RouteConstant.SUPPORT,RouteConstant.CLIENT_SUPPORT,RouteConstant.CONTACT_US),
						icon: 'fsmicon-cse-o'
					},
					{
						title: '#FollowUsTemplate'
					}
				]
			},
			{
				bannerClass: '',
				mobileMenuClass: 'd-none',
				bannerChildren: [
					{ bannerImage: 'our-service-banner-1' },
					{ bannerImage: 'our-service-banner-2' }
				]
			}
		]
	},
	{
		title: 'INSIGHT',
		titleKey: 'fsmone.menu.insight',
		url: '',
		clickable: true,
		childrenGrid: [[0], [1], [[2, 3, 4], [5]]],
		children: [
			{
				title: 'MARKET TREND',
				titleKey: 'fsmone.menu.market.trend',
				children: [
					{
						title: 'Best/Worst Performing',
						titleKey: 'fsmone.menu.best.worst.performing',
						url: genRouterLink(RouteConstant.FUNDS,RouteConstant.MARKET_TREND,RouteConstant.BEST_AND_WORST_PERFORMERS),
						icon: 'fsmicon-gainer-o',
						desc: ['bond', 'fsmone.fund', 'non.bond.fund']
					},
					{
						title: 'Highest Dividend Paying Fund',
						titleKey: 'fsmone.menu.highest.dividend.paying.fund',
						url: genRouterLink(RouteConstant.FUNDS,RouteConstant.MARKET_TREND,RouteConstant.HIGHEST_DIVIDEND_YIELD),
						icon: 'fsmicon-holder-put-o',
						desc: ['fsmone.fixed.income.fund', 'fsmone.non.fixed.income.fund']
					},
					{
						title: 'Top Volume',
						titleKey: 'fsmone.menu.top.volume',
						url: genRouterLink(RouteConstant.INSIGHT,RouteConstant.MARKET_TREND,RouteConstant.TOP_VOLUME),
						icon: 'fsmicon-indices-o',
						desc: ['bond', 'fsmone.fund']
					}				
				]
			},
			{
				title: 'FSMONE RESEARCH AND IDEAS',
				titleKey: 'fsmone.menu.fsm.research.and.ideas',
				children: [
					{
						title: 'FSMOne Fund Picks',
						titleKey: 'fsmone.menu.fund.picks',
						url: genRouterLink(RouteConstant.INSIGHT,RouteConstant.RESEARCH,RouteConstant.FUND_PICKS),
						icon: 'fsmicon-fund-hub-o'
					},
					{
						title: 'FSMOne Star Ratings',
						titleKey: 'fsmone.menu.star.ratings',
						url: genRouterLink(RouteConstant.INSIGHT,RouteConstant.RESEARCH,RouteConstant.STAR_RATING),
						icon: 'fsmicon-star-rating'
					},
					{
						title: 'Product Research',
						titleKey: 'fsmone.menu.product.research',
						url: genRouterLink(RouteConstant.INSIGHT,RouteConstant.RESEARCH,RouteConstant.FSMONE_RESEARCH_INSIGHT),
						icon: 'fsmicon-market-depth-o',
//						desc: ['bond', 'fsmone.fund', 'fsmone.etf', 'dpms.portfolios', 'stock.trade.stock']
					},
					{
						title: 'Videos',
						titleKey: 'fsmone.menu.videos',
						url: genRouterLink(RouteConstant.INSIGHT,RouteConstant.RESEARCH,RouteConstant.VIDEOS),
						icon: 'fsmicon-webinars-video-o'
					}
				]
			},
			{
				title: 'INVEST 101',
				titleKey: 'fsmone.menu.invest.101',
				children: [
					{
						title: 'Glossary',
						titleKey: 'fsmone.menu.glossary',
						url: genRouterLink(RouteConstant.INSIGHT,RouteConstant.INVEST_101,RouteConstant.GLOSSARY),
						icon: 'fsmicon-selector'
					},
					{
						title: 'How to Invest in...',
						titleKey: 'fsmone.menu.how.to.invest.in.',
						url: genRouterLink(RouteConstant.INSIGHT,RouteConstant.INVEST_101,RouteConstant.BASIC_101),
						icon: 'fsmicon-question-circle-o',
						desc: ['bond', 'fsmone.fund', 'fsmone.etf', 'stock.trade.stock']
					}
				]
			},
			{
				title: 'MARKET DATA',
				titleKey: 'fsmone.menu.market.data',
				children: [
					{
						title: 'FSMOne Indices',
						titleKey: 'fsmone.menu.indices',
						url: genRouterLink(RouteConstant.INSIGHT, RouteConstant.MARKET_DATA, RouteConstant.MARKET_INDICES, RouteConstant.FSMONE_INDICES),
						icon: 'fsmicon-indices-o'
					},
					{
						title: 'Market Indices',
						titleKey: 'fsmone.menu.market.indices',
						url: genRouterLink(RouteConstant.INSIGHT, RouteConstant.MARKET_DATA, RouteConstant.MARKET_INDICES, RouteConstant.MAJOR_INDICES),
						icon: 'fsmicon-indices-o'
					},
					{
						title: 'Market PE and Earnings',
						titleKey: 'fsmone.menu.market.pe.earnings',
						url: genRouterLink(RouteConstant.INSIGHT,RouteConstant.MARKET_DATA,RouteConstant.MARKET_INDICES, RouteConstant.MARKET_PE_EARNINGS_GROWTH),
						icon: 'fsmicon-gainer-o'
					},
					{
						title: 'Sovereign Bonds',
						titleKey: 'fsmone.bonds.intro.topic1.list5',
						url: genRouterLink(RouteConstant.BONDS,RouteConstant.MARKET_TREND,RouteConstant.SOVEREIGN_BONDS),
						icon: 'fsmicon-yield-o'
					}
				]
			},
			{
				title: 'TOOLS',
				titleKey: 'fsmone.menu.tool',
				children: [
					{
						title: 'Calculator',
						titleKey: 'fsmone.menu.calculator',
						url: genRouterLink(RouteConstant.BONDS,RouteConstant.TOOLS,RouteConstant.BOND_CALCULATOR),
						icon: 'fsmicon-calculator-o',
						desc: ['bond', 'fsmone.etf', 'stock.trade.stock']
					},
					{
						title: 'Chart Centre',
						titleKey: 'fsmone.menu.chart.centre',
						url: genRouterLink(RouteConstant.FUNDS,RouteConstant.TOOLS,RouteConstant.CHART_CENTRE),
						icon: 'fsmicon-chart-analysis-o',
						desc: ['bond', 'fsmone.fund', 'fsmone.etf']
					},
					{
						title: 'Selector',
						titleKey: 'fsmone.menu.selector',
						url: genRouterLink(RouteConstant.FUNDS,RouteConstant.TOOLS,RouteConstant.FUND_SELECTOR),
						icon: 'fsmicon-selector',
						desc: ['bond', 'fsmone.fund', 'fsmone.etf', 'stock.trade.stock']
					}
				]
			},
			{
				bannerClass: 'banner-xl',
				mobileMenuClass: 'd-none',
				bannerChildren: [
					{ bannerImage: 'insight-banner-1' }
				]
			}
		]
	},
	{
		title: 'FUNDS',
		titleKey: 'fsmone.menu.funds',
		url: genRouterLink(RouteConstant.FUNDS),
		clickable: true,
		children: [
			{
				title: 'EXPLORE FUNDS',
				titleKey: 'fsmone.menu.explore.fund',
				children: [
					{
						title: 'Fund Homepage',
						titleKey: 'fsmone.menu.fund.homepage',
						url: genRouterLink(RouteConstant.FUNDS),
						icon: 'fsmicon-fund-home-o'
					},
					{
						title: 'Fund School',
						titleKey: 'fsmone.menu.fund.school',
						url: genRouterLink(RouteConstant.FUNDS,RouteConstant.EXPLORE,RouteConstant.FUND_101),
						icon: 'fsmicon-fund-manager-o'
					},
					{
						title: 'Fund House Insight',
						titleKey: 'fund.houses.insights',
						url: genRouterLink(RouteConstant.FUNDS,RouteConstant.EXPLORE,RouteConstant.FUND_HOUSE_INSIGHT),
						icon: 'fsmicon-ideas-o'
					},
					{
						title: 'FSMOne Fund Picks',
						titleKey: 'fsmone.menu.fund.picks',
						url: genRouterLink(RouteConstant.FUNDS,RouteConstant.EXPLORE,RouteConstant.FUND_PICKS),
						icon: 'fsmicon-fund-hub-o'
					},
					{
						title: 'FSMOne Research Insight',
						titleKey: 'fsmone.menu.research.in.sight',
						url: genRouterLink(RouteConstant.FUNDS,RouteConstant.EXPLORE,RouteConstant.FSMONE_RESEARCH_INSIGHT),
						icon: 'fsmicon-ideas-o'
					},
					{
						title: 'Fees',
						titleKey: 'fsmone.menu.fees',
						url: genRouterLink(RouteConstant.FUNDS, RouteConstant.EXPLORE, RouteConstant.FEE_SCHEDULE),
						icon: 'fsmicon-fee-o'
					}
				]
			},
			{
				title: 'MARKET TREND',
				titleKey: 'fsmone.menu.market.trend',
				children: [
					{
						title: 'Best / Worst Performing',
						titleKey: 'fsmone.menu.best.worst.performing',
						url: genRouterLink(RouteConstant.FUNDS,RouteConstant.MARKET_TREND,RouteConstant.BEST_AND_WORST_PERFORMERS),
						icon: 'fsmicon-gainer-o'
					},
					{
						title: 'FSMOne Indicies',
						titleKey: 'fsmone.menu.indices',
						url: genRouterLink(RouteConstant.FUNDS,RouteConstant.MARKET_TREND,RouteConstant.MARKET_INDICES, RouteConstant.FSMONE_INDICES),
						icon: 'fsmicon-indices-o'
					},
					{
						title: 'Highest Dividend Yield',
						titleKey: 'fsmone.menu.highest.dividend.yield',
						url: genRouterLink(RouteConstant.FUNDS,RouteConstant.MARKET_TREND,RouteConstant.HIGHEST_DIVIDEND_YIELD),
						icon: 'fsmicon-yield'
					},
					{
						title: 'Market Indices',
						titleKey: 'fsmone.menu.market.indices',
						url: genRouterLink(RouteConstant.FUNDS,RouteConstant.MARKET_TREND,RouteConstant.MARKET_INDICES),
						icon: 'fsmicon-indices-o'
					}
				]
			},
			{
				title: 'TOOLS',
				titleKey: 'fsmone.menu.tool',
				children: [
					{
						title: 'Chart Centre',
						titleKey: 'fsmone.menu.chart.centre',
						url: genRouterLink(RouteConstant.FUNDS,RouteConstant.TOOLS,RouteConstant.CHART_CENTRE),
						icon: 'fsmicon-chart-centre-o'
					},
					{
						title: 'Fund Selector',
						titleKey: 'fsmone.menu.fund.selector',
						url: genRouterLink(RouteConstant.FUNDS,RouteConstant.TOOLS,RouteConstant.FUND_SELECTOR),
						icon: 'fsmicon-selector-o'
					},
					{
						title: 'Fund Restriction',
						titleKey: 'fsmone.menu.fund.restriction',
						url: genRouterLink(RouteConstant.FUNDS,RouteConstant.TOOLS,RouteConstant.FUND_RESTRICTION),
						icon: 'fsmicon-restricted-Prod-o'
					}
				]
			},
			{
				title: 'FUND INFORMATION',
				titleKey: 'fsmone.menu.fund.information',
				children: [
					{
						title: 'Fund Holiday',
						titleKey: 'fsmone.menu.fund.holiday',
						url: genRouterLink(RouteConstant.FUNDS,RouteConstant.FUND_INFO,RouteConstant.FUND_CALENDAR),
						icon: 'fsmicon-calendar-o'
					},
					{
						title: 'Fund Managers',
						titleKey: 'fsmone.menu.fund.managers',
						url: genRouterLink(RouteConstant.FUNDS,RouteConstant.FUND_INFO,RouteConstant.FUND_HOUSE),
						icon: 'fsmicon-fund-manager-o'
					},
					{
						title: 'New Fund on Board',
						titleKey: 'fsmone.menu.new.fund.on.board',
						url: genRouterLink(RouteConstant.FUNDS,RouteConstant.FUND_INFO,RouteConstant.NEW_FUNDS_ON_BOARD),
						icon: 'fsmicon-new-fund'
					},
					{
						title: 'Switching Lag Time',
						titleKey: 'fsmone.menu.switching.lag.time',
						url: genRouterLink(RouteConstant.FUNDS,RouteConstant.FUND_INFO,RouteConstant.FUND_SWITCH_LAG_TIME),
						icon: 'fsmicon-switch'
					}
				]
			},
			{
				
				bannerClass: 'banner-xs pb-2',
				mobileMenuClass: 'd-none',
				bannerChildren: [
					{	bannerImage: 'fund-banner-1' }
				]
			}
		]
	},
	{
		title: 'BONDS',
		titleKey: 'fsmone.menu.bonds',
		url: genRouterLink(RouteConstant.BONDS),
		clickable: true,
		children: [
			{
				title: 'EXPLORE BONDS',
				titleKey: 'fsmone.menu.explore.bond',
				children: [
					{
						title: 'Bond Homepage',
						titleKey: 'fsmone.menu.bond.homepage',
						url: genRouterLink(RouteConstant.BONDS),
						icon: 'fsmicon-bond-home-o'
					},
					{
						title: 'Bond School',
						titleKey: 'fsmone.article.category.bond.school',
						url: genRouterLink(RouteConstant.BONDS,RouteConstant.EXPLORE,RouteConstant.BOND_101),
						icon: 'fsmicon-school-o'
					},
					{
						title: 'Bondsupermart Live / Bond Express',
						titleKey: 'fsmone.bsm.live.slash.bond.express',
						url: genRouterLink(RouteConstant.BONDS,RouteConstant.EXPLORE,RouteConstant.BSM_LIVE),
						icon: 'fsmicon-bond-express-o'
					},
					{
						title: 'FSMOne Research Insight',
						titleKey: 'fsmone.menu.research.in.sight',
						url: genRouterLink(RouteConstant.BONDS,RouteConstant.EXPLORE, RouteConstant.FSMONE_RESEARCH_INSIGHT),
						icon: 'fsmicon-ideas-o'
					},
					{
						title: 'Newly Issued Bonds',
						titleKey: 'fsmone.menu.newly.issued.bonds',
						url: genRouterLink(RouteConstant.BONDS, RouteConstant.EXPLORE, RouteConstant.IPO),
						icon: 'fsmicon-ipo-o'
					},
					{
						title: 'Fees',
						titleKey: 'fsmone.menu.fees',
						url: genRouterLink(RouteConstant.BONDS,RouteConstant.EXPLORE, RouteConstant.FEE_SCHEDULE, RouteConstant.BONDS),
						icon: 'fsmicon-fee-o'
					},
					{
						title: 'TreasuryNow',
						titleKey: 'fsmone.treasury.now',
						url: genRouterLink(RouteConstant.BONDS,RouteConstant.EXPLORE, RouteConstant.TREASURY_NOW),
						icon: 'fsmicon-bond-express-o'
					}				
				]
			},
			{
				title: 'MARKET TREND',
				titleKey: 'fsmone.menu.market.trend',
				children: [
					{
						title: 'Best/Worst Performing',
						titleKey: 'fsmone.menu.best.worst.performing',
						url: genRouterLink(RouteConstant.BONDS,RouteConstant.MARKET_TREND,RouteConstant.BEST_AND_WORST_PERFORMERS),
						icon: 'fsmicon-gainer-o'
					},
					{
						title: 'Bond Index/Data',
						titleKey: 'fsmone.menu.bond.index.data',
						url: genRouterLink(RouteConstant.BONDS,RouteConstant.MARKET_TREND,RouteConstant.INDEX_DATA),
						icon: 'fsmicon-index-data-o'
					},
					{
						title: 'Sovereign Bonds',
						titleKey: 'fsmone.bonds.intro.topic1.list5',
						url: genRouterLink(RouteConstant.BONDS,RouteConstant.MARKET_TREND,RouteConstant.SOVEREIGN_BONDS),
						icon: 'fsmicon-yield'
					}
				]
			},
			{
				title: 'TOOLS',
				titleKey: 'fsmone.menu.tool',
				children: [
					{
						title: 'Bond Calculator',
						titleKey: 'fsmone.menu.bond.calculator',
						url: genRouterLink(RouteConstant.BONDS,RouteConstant.TOOLS,RouteConstant.BOND_CALCULATOR),
						icon: 'fsmicon-calculator-o'
					},
					{
						title: 'Bond Selector',
						titleKey: 'fsmone.menu.bond.selector',
						url: genRouterLink(RouteConstant.BONDS,RouteConstant.TOOLS,RouteConstant.BOND_SELECTOR),
						icon: 'fsmicon-selector-o'
					},
					{
						title: 'Chart Centre',
						titleKey: 'fsmone.menu.chart.centre',
						url: genRouterLink(RouteConstant.BONDS,RouteConstant.TOOLS,RouteConstant.CHART_CENTRE),
						icon: 'fsmicon-chart-centre-o'
					}					
				]
			},
			{
				bannerClass: 'banner-lg pb-2',
				mobileMenuClass: 'd-none',
				bannerChildren: [
					{	bannerImage: 'bond-banner-1' },
					{	bannerImage: 'bond-banner-2' }
				]
			}
		]
	},
	{
		title: 'ETFS',
		titleKey: 'fsmone.general.etf',
		url: genRouterLink(RouteConstant.ETFS),
		clickable: true,
		children: [
			{
				title: 'EXPLORE ETFs',
				titleKey: 'fsmone.menu.explore.etf',
				children: [
					{
						title: 'ETF Homepage',
						titleKey: 'fsmone.menu.etf.homepage',
						url: genRouterLink(RouteConstant.ETFS),
						icon: 'fsmicon-etf-home-o'
					},
					{
						title: 'ETFs School',
						titleKey: 'fsmone.menu.etf.school',
						url: genRouterLink(RouteConstant.ETFS,RouteConstant.EXPLORE, RouteConstant.ETF_101),
						icon: 'fsmicon-school-o'
					},
					{
						title: 'FSMOne Research Insight',
						titleKey: 'fsmone.menu.research.in.sight',
						url: genRouterLink(RouteConstant.ETFS,RouteConstant.EXPLORE,RouteConstant.FSMONE_RESEARCH_INSIGHT),
						icon: 'fsmicon-ideas-o'
					},
					{
						title: 'Fees',
						titleKey: 'fsmone.menu.fees',
						url: genRouterLink(RouteConstant.ETFS,RouteConstant.EXPLORE, RouteConstant.FEE_SCHEDULE, RouteConstant.HK_STOCK),
						icon: 'fsmicon-fee-o'
					}
				]
			},
			{
				title: 'MARKET TREND',
				titleKey: 'fsmone.menu.market.trend',
				children: [
					{
						title: 'Best/Worst Performing',
						titleKey: 'fsmone.menu.best.worst.performing',
						url: genRouterLink(RouteConstant.ETFS,RouteConstant.MARKET_TREND,RouteConstant.BEST_AND_WORST_PERFORMERS),
						icon: 'fsmicon-gainer-o'
					},
					{
						title: 'Market Indices',
						titleKey: 'fsmone.menu.market.indices',
						url: genRouterLink(RouteConstant.ETFS,RouteConstant.MARKET_TREND,RouteConstant.MARKET_INDICES),
						icon: 'fsmicon-index-data-o'
					}
				]
			},
			{
				title: 'TOOLS',
				titleKey: 'fsmone.menu.tool',
				children: [
					{
						title: 'Chart Centre',
						titleKey: 'fsmone.menu.chart.centre',
						url: genRouterLink(RouteConstant.ETFS,RouteConstant.TOOLS,RouteConstant.CHART_CENTRE),
						icon: 'fsmicon-chart-centre-o'
					},{
						title: 'ETF Calculator',
						titleKey: 'fsmone.menu.etf.calculator',
						url: genRouterLink(RouteConstant.ETFS,RouteConstant.TOOLS,RouteConstant.ETFS_CALCULATOR),
						icon: 'fsmicon-calculator-o'
					},
					{
						title: 'ETF Selector',
						titleKey: 'fsmone.menu.etf.selector',
						url: genRouterLink(RouteConstant.ETFS,RouteConstant.TOOLS,RouteConstant.ETFS_SELECTOR),
						icon: 'fsmicon-selector-o'
					}
				]
			},
			{
				title: 'ETF INFORMATION',
				titleKey: 'fsmone.menu.etf.information',
				children: [
					{
						title: 'ETF Managers',
						titleKey: 'fsmone.menu.etf.managers',
						url: genRouterLink(RouteConstant.ETFS,RouteConstant.ETF_INFO,RouteConstant.MANAGER_LISTING),
						icon: 'fsmicon-fund-manager-o'
					}
				]
			},
			{
				
				bannerClass: 'banner-sm pb-2',
				mobileMenuClass: 'd-none',
				bannerChildren: [
					{
						bannerImage: 'etf-banner-1',
						bannerText: [
							{ text: 'FSMOne', cssClass: 'text-white text-lg' },
							{ textKey: 'fsmone.recommended.fund', cssClass: 'text-white font-weight-bold text-xl' }
						],
						url: genRouterLink(RouteConstant.FUNDS, RouteConstant.EXPLORE, RouteConstant.FUND_PICKS)
					}
				]
			}
		]
	},
	{
		title: 'STOCKS',
		titleKey: 'fsmone.menu.stocks',
		url: genRouterLink(RouteConstant.STOCKS),
		clickable: true,
		children: [
			{
				title: 'EXPLORE STOCKS',
				titleKey: 'fsmone.menu.explore.stock',
				children: [
					{
						title: 'Stock Homepage',
						titleKey: 'fsmone.menu.stock.homepage',
						url: genRouterLink(RouteConstant.STOCKS),
						icon: 'fsmicon-stock-home-o'
					},
					{
						title: 'Stock School',
						titleKey: 'stock.school',
						url: genRouterLink(RouteConstant.STOCKS,RouteConstant.EXPLORE,RouteConstant.STOCK_101),
						icon: 'fsmicon-legal-o'
					},
					{
						title: 'Stocks IPO',
						titleKey: 'fsmone.menu.stock.ipo',
						url: genRouterLink(RouteConstant.STOCKS,RouteConstant.EXPLORE,RouteConstant.STOCKS_IPO),
						icon: 'fsmicon-ipo-o'
					},
					{
						title: 'FSMOne Research Insight',
						titleKey: 'fsmone.menu.research.in.sight',
						url: genRouterLink(RouteConstant.STOCKS,RouteConstant.EXPLORE,RouteConstant.FSMONE_RESEARCH_INSIGHT),
						icon: 'fsmicon-managed-portfolio-insights-o'
					},
					{
						title: 'Fees',
						titleKey: 'fsmone.menu.fees',
						url: genRouterLink(RouteConstant.STOCKS,RouteConstant.EXPLORE, RouteConstant.FEE_SCHEDULE, RouteConstant.HK_STOCK),
						icon: 'fsmicon-fee-o'
					}
				]
			},
			{
				title: 'MARKET TREND',
				titleKey: 'fsmone.menu.market.trend',
				children: [
					{
						title: 'Best/Worst Performing',
						titleKey: 'fsmone.menu.best.worst.performing',
						url: genRouterLink(RouteConstant.STOCKS,RouteConstant.MARKET_TREND,RouteConstant.BEST_AND_WORST_PERFORMERS),
						icon: 'fsmicon-gainer-o'
					},
					{
						title: 'Market Performances',
						titleKey: 'fsmone.menu.market.performances',
						url: genRouterLink(RouteConstant.STOCKS,RouteConstant.MARKET_TREND,RouteConstant.MARKET_PERFORMANCE_DETAIL),
						icon: 'fsmicon-market-depth-o'
					},
					{
						title: 'Top Turnover',
						titleKey: 'fsmone.menu.stock.top.turnover',
						url: genRouterLink(RouteConstant.STOCKS,RouteConstant.MARKET_TREND,RouteConstant.TOP_TURNOVER),
						icon: 'fsmicon-yieldchart-o'
					}
				]
			},
			{
				title: 'TOOLS',
				titleKey: 'fsmone.menu.tool',
				setTransform: false,
				children: [
					{
						title: 'Stock Calculators',
						titleKey: 'fsmone.menu.stock.calculator',
						url: genRouterLink(RouteConstant.STOCKS,RouteConstant.TOOLS,RouteConstant.STOCKS_CALCULATOR),
						icon: 'fsmicon-calculator-o'
					},
					{
						title: 'Stock Selector',
						titleKey: 'fsmone.menu.stock.selector',
						url: genRouterLink(RouteConstant.STOCKS,RouteConstant.TOOLS,RouteConstant.STOCKS_SELECTOR),
						icon: 'fsmicon-selector-o'
					},
				]
			}, 
			{
				bannerClass: 'banner-lg pb-2',
				mobileMenuClass: 'd-none',
				bannerChildren: [
					{	bannerImage: 'stock-banner-1' }
				]
			}
		]
	},
	{
		title: 'HKD 50,000 PORTFOLIO',
		titleKey: 'fsmone.menu.hkd.50000.portfolio',
		url: genRouterLink(RouteConstant.DPMS),
		clickable: true
	},	
	{
		title: 'iFAST TV',
		url: environment.ifastTvUrl,
		clickable: true,
		icon: ICON_PATH + 'confirmed_ifast_tv_logo.svg',
	}
]