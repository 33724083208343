export class ApiConstant {
  // Operations
  public static readonly BASE_API_URL: string = '/rest-api';
  public static readonly READ: string = '/read';
  public static readonly CREATE: string = '/create';
  public static readonly REMOVE: string = '/remove';
  public static readonly DELETE: string = '/delete';
  public static readonly UPDATE: string = '/update';
  public static readonly ADD: string = '/add';
  public static readonly DOWNLOAD: string = '/download';
  public static readonly REQUEST: string = '/request';
  public static readonly BUY: string = '/buy';
  public static readonly SELL: string = '/sell';
  public static readonly SWITCH: string = '/switch';
  public static readonly VOID: string = '/void';
  public static readonly EPF: string = '/epf';
  public static readonly DATA: string = '/data';
  public static readonly SEARCH: string = '/search';
  public static readonly TERMINATE: string = '/terminate';
  public static readonly PROTECTED: string = '/protected';
  public static readonly AUTHENTICATE: string = '/authenticate';
  public static readonly VALIDATE: string = '/validate';
  public static readonly RESET: string = '/reset';
  public static readonly SEND: string = '/send';
  public static readonly GENERATE: string = '/generate';
  public static readonly COMMAND: string = '/command';
  public static readonly UNSUBCRIBE: string = '/unsubcribe';
  public static readonly VERIFY: string = '/verify';
  public static readonly HIDE: string = '/hide';
  public static readonly REFRESH: string = '/refresh';
  public static readonly QUICK: string = '/quick';
  public static readonly TRANSACTION: string = '/transaction';
  public static readonly FORM: string = '/form';
  public static readonly RESEND: string = '/resend';
  public static readonly EMAIL: string = '/email';
  public static readonly VERIFICATION: string = '/verification';
  public static readonly UNVERIFIED = '/unverified';
  public static readonly FORCE_UPDATE = '/force-update';
  public static readonly DETAILS = '/details';
  public static readonly OTP = '/otp';
  public static readonly ACTIVE = '/active';
  public static readonly CONSOLIDATED = '/consolidated';
  public static readonly OTHER_INFO = '/other-info'
  public static readonly LIST = '/list'
  public static readonly CHART = '/chart'
  public static readonly DEPOSIT = '/deposit';
  public static readonly WITHDRAWAL = '/withdrawal';
  public static readonly BALANCE = '/balance';
  public static readonly PENDING = '/pending';
  public static readonly HISTORY = '/history';
  public static readonly ORDER: string = '/order';
  public static readonly AUTH: string = '/auth';
  public static readonly RSP: string = '/rsp';
  public static readonly ADDITIONAL = '/additional';
  public static readonly ACCEPT = '/accept';
  public static readonly PRS_BUY = '/prs-buy';
  public static readonly SUBSCRIBE = '/subscribe';
  public static readonly ACTIVE_OR_CREATE = '/active-or-create';
  public static readonly WEB = '/web';
  public static readonly BANK_DETAILS = '/bank-details';
  public static readonly WEBCAST  = '/webcast';
  public static readonly EVENTS = '/events';

  // Base Urls
  public static readonly ARTICLE: string = '/article';
  public static readonly UTILITY: string = '/utility';
  public static readonly FUND: string = '/fund';
  public static readonly STOCKS: string = '/stock';
  public static readonly STOCK_IPO: string = '/stock-ipo';
  public static readonly STOCKS_DATA: string = '/stock-data';
  public static readonly STOCK_INFO: string = '/stock-info';
  public static readonly ETFS: string = '/etfs';
  public static readonly ETF: string = '/etf';
  // public static readonly STOCKS_DATA: string = '/stock/stock-data';
  public static readonly BOND: string = '/bond';
  public static readonly BOND_IPO: string = '/bond-ipo';
  public static readonly CAREERS: string = '/careers';
  public static readonly ACCOUNT: string = '/account';
  public static readonly ACCOUNT_OPENING: string = '/account-opening';
  public static readonly EKYC: string = '/ekyc';
  public static readonly QR_CODE: string = '/qr-code';
  public static readonly TEMP: string = '/temp';
  public static readonly PORTFOLIO_SIMULATOR: string = '/portfolioSimulator'
  public static readonly SECURITY = '/security';
  public static readonly DPMS = '/dpms';
  public static readonly HOLDING = '/holding';
  public static readonly SUPPORT = '/support';
  public static readonly FEEDBACK = '/feedback';
  public static readonly FAQ = '/faq';
  public static readonly PRODUCT = '/product';
  public static readonly AUTHORITY: string = '/authority';
  public static readonly TRUST_ACCOUNT = '/trust-account';
  public static readonly PAYMENT_SLIP = '/payment-slip';
  public static readonly PAYMENT = '/payment';
  public static readonly PAYMENT_MODE = '/payment-mode';
  public static readonly PRS = '/prs';
  public static readonly RESEARCH = '/research';
  public static readonly CENTRAL_BANK_RATES = '/central-bank-rates';
  public static readonly FOREIGN_EXCHANGE = '/foreign-exchange';
  public static readonly SETTLEMENT = '/settlement';
  public static readonly CDS_ACCOUNT = '/cds-account';
  public static readonly INSURANCE = '/insurance';
  public static readonly SUBSCRIPTION: string = '/subscription';
  public static readonly WATCHLIST = '/watchlist';
  public static readonly ANNOUNCEMENT = '/announcement';
  public static readonly REPORT = '/report';
  public static readonly PRODUCT_PRICE_ALERT = '/product-price-alert';
  public static readonly PRODUCT_PRICE_ALERT_HIST = '/product-price-alert/hist';
  public static readonly FEE = '/fee';
  public static readonly HOME = '/home';
  public static readonly LANG = '/lang';
  public static readonly MODULE = '/module';
  public static readonly EDDA = '/edda';
  public static readonly FORGOT_LOGIN_ID: string = '/forgot-login-id';
  public static readonly FORGOT_PASSWORD: string = '/forgot-password';
  public static readonly CORP_FIRST_LOGIN: string = '/corp-first-login';
  public static readonly ALERT = '/alert';

  public static readonly BOND_TRADE: string = '/bond-trade';
  public static readonly ACCOUNT_UPDATE: string = '/account-update';
  public static readonly TWO_FACTOR_AUTH: string = '/two-factor-auth';
  // Arranged according to REST controller

  // Locale
  public static readonly CHANGE_LANG_FSMONE = '/change-lang-fsmone';

  // Article
  public static readonly FIND_HIGHLIGHT: string = '/find-highlight';
  public static readonly FIND_RCMS_ARTICLE_LIST: string = "/find-rcms-article-list";
  public static readonly GET_ARTICLE: string = '/get-article';
  public static readonly GET_RCMS_ARTICLE_DETAILS: string = '/get-rcms-article-details';
  public static readonly GET_ARTICLE_TAGS: string = '/find-rcms-article-tags';
  public static readonly GET_ARTICLE_AUTHORS: string = '/find-rcms-article-authors';
  public static readonly FIND_RCMS_ARTICLE_LIST_BY_CRITERIA: string = "/find-article-list-by-criteria";
  public static readonly FIND_RCMS_ARTICLE_LIST_COUNT: string = "/find-article-list-count";
  public static readonly FIND_RCMS_ARTICLE_LIST_BY_API: string = "/find-article-list-by-api";
  public static readonly FIND_RCMS_ARTICLE_MODEL_LIST: string = "/find-article-model-list";
  public static readonly FIND_ALL_ARTICLE_LIST: string = '/get-article-list';
  public static readonly FIND_LANDING_PAGE_ARTICLE_LIST: string = '/get-landing-page-article-list';
  public static readonly FIND_PRODUCT_PAGE_ARTICLE_LIST: string = '/get-product-landing-page-article-list';
  public static readonly FIND_SCHOOL_ARTICLE_LIST: string = '/get-school-article-list';
  public static readonly FIND_SCHOOL_ARTICLE_LIST_FROM_RCMS: string = '/get-school-article-list-from-rcms';
  public static readonly FIND_ARTICLE_DETAILS: string = '/get-article-details';
  public static readonly FIND_ARTICLE_DETAILS_FROM_DB: string = '/get-db-article-details';
  public static readonly FIND_ARTICLE_TAG_LIST: string = '/get-article-tags-list';

  public static readonly FIND_INSURANCE_ARTICLE_LIST: string = '/latest-insurance/read';
  public static readonly FIND_ALL_INSURANCE_ARTICLE_LIST: string = '/latest-insurance/all/read';
  public static readonly CATEGORY_TAGS: string = '/category-tags';
  public static readonly UPDATE_ARTICLE_HITS: string = '/update-article-hits';
  public static readonly UPDATE_INS_ARTICLE_HITS: string = '/update-article-ins-hits';
  public static readonly FIND_AUTHORS: string = '/authors';
  public static readonly RELATED_ARTICLES: string = '/product-related-articles';
  public static readonly ARTICLE_FROM_RCMS: string = '/article-from-rcms';
  public static readonly GET_ARTICLE_BY_CATEGORY = '/get-article-by-category-id';
  public static readonly GET_MAIN_ARTICLE_WITH_BANNER_BY_CATEGORY_ID = '/get-main-article-with-banner-by-category-id';
  public static readonly GET_ARTICLE_PATH = '/get-article-path';
  public static readonly FIND_PORTFOLIO_MATERIALS = '/find-portfolio-materials';
  public static readonly GET_ARTICLES_TAGS_FROM_RCMS = '/get-articles-tags-from-rcms';
  public static readonly GET_ARTICLE_DETAILS_NO_CLICK_COUNT: string = '/get-article-details-no-count';
  public static readonly UPDATE_RCMS_VIEW_COUNT: string = '/update-rcms-view-count';

  //Account
  public static readonly TOTAL_ACTIVE_ACCOUNT: string = '/total-active-account';
  public static readonly FORGET_ID: string = '/forget-id';
  public static readonly IS_ALREADY_OPT_IN_OTP = '/is-already-opt-in-otp';
  public static readonly FORGET_PASSWORD: string = '/forget-password';
  public static readonly PASSWORD_STRENGTH: string = '/password-strength';
  public static readonly PASSWORD: string = '/password';
  public static readonly ID: string = '/id';
  public static readonly REMINDER_ANSWER: string = '/reminder-answer';
  public static readonly ID_EXIST: string = '/id-exist';
  public static readonly NRIC_EXIST: string = '/nric-exist';
  public static readonly FIND_OCCUPATIONS = '/find-occupations';
  public static readonly SEND_ENQUIRY = '/send-enquiry-cse';
  public static readonly GET_INVESTMENT_ADVICE = '/send-enquiry-cis'
//  public static readonly CREATE_ACCOUNT = '/create-account';
  public static readonly CREATE_IAM_ID_TEMP_IAM_ACCOUNT = '/iam-id-temp-iam-account/create';
  public static readonly FIND_TEMP_IAM_ACCOUNT_INFO = '/protected/temp/read';
  public static readonly FIND_TEMP_ACCOUNT_OPENING = '/temp/account-opening/read';
  public static readonly UPDATE_TEMP_IAM_ACCOUNT_INFO = '/temp/account-opening/update';
  public static readonly GET_BANK_DETAILS = '/bank-details/read';
  public static readonly CREATE_BANK_DETAILS = '/bank-details/create';
  public static readonly BANK = '/bank';
  public static readonly OPENING_DETAILS = '/opening-details';
  public static readonly PDF_PASSWORD = '/pdf-password';
  public static readonly FIND_REMINDER_QUESTIONS = '/find-reminder-questions';
  public static readonly REMINDER = '/reminder';
  public static readonly TWO_FA = '/two-fa';
  public static readonly EMAIL_VERIFY = '/email-verify/trigger';
  public static readonly EMAIL_VALIDATE = '/email-verify/validate';
  public static readonly MAILING_LIST_FLAG = '/mailing-list-flag';
  public static readonly SUPPORTING_DOC_EXIST = '/supporting-doc-exist';
  public static readonly ALL_ACCOUNT_DETAILS = '/all-account-details';
  public static readonly PPA_NUMBER = '/ppa-number';
  public static readonly BANK_DETAILS_EXIST = '/bank-details-exist';
  public static readonly AI = '/ai';
  public static readonly STOCK_LEVERAGED_INVERSE_RISK_DISCLOSURE = '/stock-leveraged-inverse-risk-disclosure';
  public static readonly SEND_TRANSFER_ENQUIRY_FORM = '/send-transfer-enquiry-form';
  public static readonly NEW_TNC_AGREEMENT = '/new-tnc-agreement';
  public static readonly GET_REFERRAL_REWARDS_COUNT = "/get-referral-rewards-count";
  public static readonly SEND_REFERRAL_PROGRAM_FORM = "/send-referral-program-form";
  public static readonly PRIVACY_POLICY: string = '/privacy-policy';
  public static readonly REMIND_LATER: string = '/remind-later';
  public static readonly SEND_FEEDBACK = '/send-enquiry-ux'
  public static readonly OPT_IN = '/opt-in'
//  public static readonly EMIS_ACCOUNT_DETAILS_UPDATE = '/emis-account-details-update'
//  public static readonly EMIS_SUPPORTING_DOC = '/emis-supporting-doc'
//  public static readonly EMIS_ACCOUNT = '/emis-acct'
  public static readonly CURRENCY = '/currency';
  public static readonly GET_FX_RATES_HKD_BASE = '/get-trading-currency-latest-system-rates-from-hkd';
  public static readonly GET_LATEST_SYSTEM_TRADING_RATE = '/get-latest-system-trading-rate';

  public static readonly ACCOUNT_OPENING_DEPOSIT = '/account-opening-deposit';
//  public static readonly BUMIPUTERA = '/bumiputera';
  public static readonly ACCOUNT_OPENING_DOCUMENTS = '/account-opening-documents';
  public static readonly ALL_TIER_STATUS = '/tier-status/all';
  public static readonly SUBMIT_FSMONE_ENQUIRY = '/submit-fsmone-enquiry';
  public static readonly SUBMIT_ENQUIRY = '/submit-enquiry';
  public static readonly SUBMIT_CIS_APMT = '/submit-cis-appointment';
  public static readonly GET_CIS_AVAILABLE_TIMESLOT = '/get-cis-available-timeslot';
  public static readonly GET_COUNTRY_LIST = '/get-country-list';
  public static readonly GET_ID_ISSUE_COUNTRY_LIST = '/get-id-issuer-country-list';
  public static readonly GET_OCCUPATION_LIST = '/get-occupation-list';
  public static readonly GET_BUSINESS_NATURE_LIST = '/get-business-nature-list';
  public static readonly GET_BANK_LIST = '/get-bank-list';
  public static readonly GET_FAFT_COUNTRY_LIST = '/get-faft-country-list';
  public static readonly SUBMISSION_PRE_CHECK = '/submission-pre-check';
  public static readonly ADD_ACCOUNT_LANDING_CHECK = '/additional-acct-landing';
  public static readonly ADD_ACCOUNT_PRECHECK = '/additional-account-precheck';
  public static readonly GET_ADD_ACCOUNT_DETAILS = '/get-additional-acct-opening-details';
  public static readonly SUBMIT_ACCOUNT_OPENING = '/submit-account-opening';
  public static readonly SUBMIT_ADD_ACCOUNT_OPENING = '/submit-additonal-account-opening';
  public static readonly VALIDATE_HKID = '/validate-hkid';
  public static readonly PRINT_ACCT_FORM_PDF = '/print-account-opening-form-pdf';
  public static readonly GET_CIS_AVAILABLE_TIMESLOT_ACC_OPENING = '/get-cis-available-timeslot-acc-opening';
  public static readonly GET_BOOKED_APPOINTMENT_ACC_OPENING = '/get-booked-appointment-acc-opening';
  public static readonly SUBMIT_CIS_APMT_ACC_OPENING = '/submit-cis-appointment-acc-opening';

  public static readonly FEP = '/fep';
  public static readonly CHECK_ACCOUNT_ACTIVATION = '/check-account-activation';
  public static readonly ACCOUNT_BASIC_INFO = '/get-account-basic-info';
  public static readonly ACCOUNT_BASIC_INFO_BY_REFNO = '/get-account-basic-info-by-refno';
  public static readonly GET_PRIMARY_ACCOUNT = '/get-primary-account';
	public static readonly PRIMARY_ACCOUNT = '/get-primary-account';
  public static readonly HAS_D_ACCOUNT = '/has-dpms-account';
  public static readonly GET_PREFERRED_TRANSACT_REFNO = '/get-preferred-transact-refno';
  public static readonly GET_TRADING_REFNO_LIST = '/get-trading-refno-list';
  public static readonly GET_TRADING_REFNO_DETAIL_LIST = '/get-trading-refno-detail-list';
  public static readonly GET_ACCOUNT_LIST_BY_ORDER = '/get-account-list-by-order';
  public static readonly GET_AUTO_SWEEP_ACCOUNT = '/get-auto-sweep-account';
  public static readonly CHECK_IS_PLATINUM_PLUS_ACC = '/check-platinum-plus-account';
  public static readonly FIND_ACCOUNT_LIST_NON_DPMS = '/find-account-list-non-dpms';
  public static readonly FIND_ALL_BANKS_FOR_DDA = '/find-all-banks-for-dda';
  public static readonly GET_BANK_ACCOUNT_DETAILS = '/get-bank-account-details';
  public static readonly GET_DETAILS_FROM_ACCOUNT = '/get-details-from-account';
  public static readonly GET_ACCOUNT_PRODUCT_STATUS = '/get-account-product-status';
  public static readonly IS_ACCOUNT_SUSPEND_TRADING = '/is-account-suspend-trading';
  public static readonly GET_DERIVATIVE_CONSENT = '/get-derivative-consent';
  public static readonly UPDATE_DERIVATIVE_CONSENT = '/update-derivative-consent';
  public static readonly GET_W8BEN_INFO_BY_REFNO = '/get-w8ben-info-by-refno';
	public static readonly GET_W8BEN_INFO_LIST_BY_ID = '/get-w8ben-info-list-by-id';
  public static readonly FIND_HK_IDR_ACCOUNT_DETAIL_LIST = '/find-hk-stock-idr-account-details-list';
	public static readonly GET_W8BEN_PDF ='/get-w8-pdf';
	public static readonly FIND_W8BEN_ACTIVE_ACCOUNT_DETAILS = '/find-w8-active-account-details-list';
	public static readonly UPLOAD_W8BEN_FORM = '/upload-activate-w8ben-form';
	public static readonly DOWNLOAD_W8_PDF ='/download-w8-pdf';
	public static readonly GET_HKIDR_ACCT_LIST = '/get-hkidr-acct_list';
	public static readonly GET_HKIDR_FORM_DETAILS = '/get-hkidr-form-details';
	public static readonly CREATE_HK_STOCKS_IDR = '/create-hk-stocks-idr';

  public static readonly GET_ALL_REFNO_LIST_WITH_TYPE = '/get-all-refno-list-with-type';
  public static readonly GET_ALL_REFNO_LIST_WITH_TYPE_AND_RPQ_COUNT = '/get-all-refno-list-with-type-and-rpq-count';
  public static readonly GET_NO_ANSWER_REFNO_LIST_WITH_TYPE = '/get-no-answer-refno-list-with-type';
  public static readonly FIND_FHK_DPMS_VALID_ACCOUNT = '/find-fhk-dpms-valid-account';
  public static readonly FIND_FHK_CONFIRMED_ACCOUNT = '/find-fhk-confirmed-account';
  public static readonly IS_PROFESSIONAL_INVESTOR_ACCOUNT = '/is-professional-investor-account';
  public static readonly IS_EACCOUNT = '/is-eaccount';

  public static readonly GET_COUNTRY_CODE_LIST ='/get-country-code3-list';

  //Account Update
  public static readonly GET_PRODUCT_STATUS = '/get-product-status';
  public static readonly SUBMIT_ADDRESS_PROOF = '/submit-address-proof';
  public static readonly GET_ALL_BANK_ACCOUNT_DETAILS = '/get-all-bank-account-details';
  public static readonly GET_BANK_NAME_LIST = '/get-bank-name-list';
  public static readonly GET_BANK_NAME_LIST_BY_CURRENCY = '/get-bank-name-list-by-currency';
  public static readonly CONFIRM_UPDATE_BANK_INFO = '/confirm-update-bank-info';
  public static readonly GET_IGB_CONSENT = '/get-igb-consent';
  public static readonly ACCEPT_LINK_IGB = '/accept-link-igb';
  public static readonly UNLINK_IGB_ACCOUNT = '/unlink-igb-account';
  public static readonly IS_IGB_ENABLED = '/is-igb-enabled';
  public static readonly GET_RPQ_QUESTION_ANSWER = '/get-rpq-question-answer';
  public static readonly CHECK_RPQ_CONSECUTIVE_UPDATE = '/check-rpq-consecutive-update';
  public static readonly VALIDATE_SAVE_GET_RPQ_RESULT_MULTIPLE = '/validate-save-get-rpq-result-multiple';
  public static readonly VALIDATE_RPQ_AGE = '/validate-rpq-age';
  public static readonly GET_USER_RISK_PROFILING_DATA = '/get-user-risk-profiling-data';
  public static readonly UPDATE_OCCUPATION = '/update-occupation';
  public static readonly GET_EXPIRED_RPQ_REFNO_LIST_DPMS_INCLUDED_WITH_TYPE = '/get-expired-rpq-refno-list-dpms-included-with-type';
  public static readonly PWD_EXP_REMINDED: string = '/password-expiry-reminded';
  public static readonly GET_ACCOUNT_OPENING_ADDRESS_LOOKUP = '/get-account-opening-address-lookup';
  public static readonly GET_STOCK_WATCHLIST_PREFERENCE = '/get-stock-watchlist-preference';
  public static readonly UPDATE_STOCK_WATCHLIST_PREFERENCE = '/update-stock-watchlist-preference';
  public static readonly GET_RPQ_PREFILL = "/get-rpq-prefill";
  public static readonly UPDATE_GENERAL_STOCK_WATCHLIST_SEQ = '/update-general-stock-watchlist-seq';

  //Dka
  public static readonly DKA = '/dka';

  //Ekyc
  public static readonly EKYC_NRIC_FRONT = '/ekyc-nric-front';
  public static readonly EKYC_NRIC_BACK = '/ekyc-nric-back';
  public static readonly IS_EKYC_AVAILABLE = '/is-ekyc-available'
  public static readonly IS_EKYC = '/is-ekyc'
  public static readonly MOBILE = '/mobile'
  public static readonly NRIC_FRONT = '/nric-front';
  public static readonly OCR_ADDRESS = '/ocr-address';
  // Utility
  public static readonly FIND_COUNTRIES: string = '/find-countries';
  public static readonly FIND_TAX_RESIDENCY_QUESTIONS_ALL: string = '/tax-residency-question-all/read';
  public static readonly FIND_STATES: string = '/states-list/read';

  //product
  public static readonly GET_TOTAL_INVESTMENT_PRODUCT: string = '/get-total-investment-product';
  public static readonly GET_TOTAL_TRANSACTION_AMOUNT: string = '/get-total-transaction-amount';

  // Fund
  public static readonly GET_FACTSHEET: string = '/get-factsheet';
  public static readonly FIND_PRICE_HISTORY: string = '/find-price-history';
  public static readonly FACTSHEET_FUND_RETURNS: string = '/get-factsheet-fund-returns';
  public static readonly FIND_FUND_DISTRIBUTION_HISTORY: string = '/find-fund-distribution-history';
  public static readonly FIND_ALL_ACTIVE_FUND_HAPPENING_HEADER: string =
    '/find-all-active-fund-happening-header';
  public static readonly FIND_ALL_NON_ACTIVE_FUND_HAPPENING_HEADER: string =
    '/find-all-non-active-fund-happening-header';
  public static readonly GET_FUND_FINDER_DATA: string = '/get-fund-finder-data';
  public static readonly FIND_FUND_HAPPENING_REGISTER: string = '/find-fund-happening-register';
  public static readonly VALIDATE_AND_CREATE_FUND_HAPPENING_REGISTER: string =
    '/fund-happening-register/validate-create';
  public static readonly GET_FUND_HAPPENING_HEADER: string =
    '/fund-happening-header-details/read';
  public static readonly GET_TOP_VOLUME_FUND_RANKING: string = '/top-vol-fund-ranking';
  public static readonly GET_BEST_PERFORMANCE_FUND_RANKING: string = '/top-performance-fund-ranking';
  public static readonly GET_WORST_PERFORMANCE_FUND_RANKING: string = '/worst-performance-fund-ranking';
  public static readonly GET_BID_PRICE_CHART_DATA: string = '/get-bid-price-chart-data';
  public static readonly BUSINESS_REPLY_ENVELOPES = '/business-reply-envelopes';
  public static readonly GET_BEST_WORST_PERFORMANCE_FUNDS: string = '/best-worst-performance-funds';
  public static readonly GET_BEST_PERFORMANCE: string = '/top-performance';
  public static readonly GET_WORST_PERFORMANCE: string = '/worst-performance';
  public static readonly GET_TOP_VOL_FUNDS: string = '/get-top-volume-funds';
  public static readonly FIND_FUND_SELECTOR_OPTIONS: string = '/find-fund-selector-options';
  public static readonly GET_FUND_SELECTOR_RESULT_ADDITIONAL_INFO: string = '/find-fund-selector-result-additional-info';
  public static readonly FIND_PRS_CATEGORY: string = '/find-prs-category';
  public static readonly GET_RECOMMENDED_FUND_LIST: string = '/find-recommended-funds-list';
  public static readonly FIND_NEW_FUNDS: string = '/find-new-funds';
  public static readonly GET_ALL_FUND_MANAGER: string = '/get-all-fund-manager-for-chart-center';
  public static readonly GET_CASH_MANAGEMENT_FUND_DETAILS: string = '/get-cash-management-fund-details';
  public static readonly GET_FUND_MANAGERS_AND_FUNDS_TOTAL_LIST: string = '/get-fund-managers-and-funds-total-list';
  public static readonly FIND_FUND_HOLIDAYS: string = '/find-fund-holidays';
  public static readonly FIND_PUBLIC_HOLIDAYS: string = '/find-public-holidays'
  public static readonly FIND_RECOMMENDED_FUNDS_AND_CATEGORY_LIST: string = '/find-recommended-funds-and-category-list';
  public static readonly FIND_DIVIDEND_ANNOUNCEMENTS: string = '/find-dividend-announcements';
  public static readonly GET_SUM_INVEST_AMOUNT: string = '/sum-invest-amount';
  public static readonly FIND_LATEST_FUND_HAPPENING_HEADER: string = '/latest-fund-happening-header/read';
  public static readonly GET_CASH_MANAGEMENT_FUND_INFO: string = '/cash-management-fund-info/read';
  public static readonly GET_INDEX_FUND_INFO: string = '/index-fund-list/read';
  public static readonly GET_DATA_FEED: string = '/get-data-feed';
  public static readonly GET_DATA_MARKET_PERFORMANCE: string = '/get-market-performance-table';
  public static readonly GET_FSM_INDICES_DATA: string = '/get-fsm-indices-data';
  public static readonly GET_FUND_INDICES_DATA: string = '/get-fund-indices-data';

  public static readonly GET_DATA_FEED_CHARTS: string = '/get-feed-charts';
  public static readonly GET_FSM_DATA_FEED_CHARTS: string = '/get-fsm-feed-charts';
  public static readonly GET_FUND_INFO: string = '/fund-info/read';
  public static readonly GET_INDEX_FUND_BY_COUNTRY_REGION: string = '/index-fund-by-country-region/read';
  public static readonly GET_MARKET_INDICES_DATA: string = '/market-indices-data/read';
  public static readonly GET_MARKET_INDICES: string = '/get-market-indices-data';
  public static readonly GET_FSM_INDICES_DETAILS: string = '/get-fsm-indices-details-data';
  public static readonly DETAILS_INFO: string = '/details-info';
  public static readonly GET_ALL_ACTIVE_FUND_LIST: string = '/get-all-active-funds-list';
  public static readonly GET_MARKET_INDICES_CHART_DATA: string = '/market-indices-eps-chart/read';
  public static readonly GET_MARKET_INDICES_CHART: string = '/market-indices-eps-chart';
  public static readonly FUND_DETAILS: string = '/fund-details';
  public static readonly GET_FUND_HAPPENING_HEADER_BY_ARTICLE_NO = '/fund-happening-header-by-article-no';
  public static readonly FIND_ALL_FUND_MANAGER = '/find-all-fund-manager';
  public static readonly FIND_SWITCH_SELL_FUNDS: string = '/find-switch-sell-funds';
  public static readonly FIND_SWITCH_BUY_FUNDS: string = '/find-switch-buy-funds';
  public static readonly SWITCHING_CALCULATOR_RESULT:string='/switching-calculator-result';
  public static readonly DEFAULT_CURRENCY_RATE='/default-currency-rate';
	public static readonly FUND_RISK_MAPPING = '/get-fund-risk-mapping-map';
	public static readonly FUND_CURRENCY_CODE_LIST = '/find-active-fund-currency-code-list';
	public static readonly GET_TOP10_HIGHEST_DIVIDEND_FUNDS = '/get-top-10-highest-dividend-funds';
	public static readonly GET_TOP10_HIGHEST_DIVIDEND_FUNDS_EXC_FI = '/get-top-10-highest-dividend-funds-exc-fi';
	public static readonly GET_FUND_RESTRICTION_COUNTRY = '/get-fund-restriction-country';
	public static readonly FIND_FUND_RESTRICTION_RESULT = '/find-fund-restriction-result';
	public static readonly GET_FUND_SWITCH_LAG_SELL_LIST= '/get-fund-switch-lag-sell-list';
	public static readonly GET_FUND_SWITCH_LAG_BUY_LIST= '/get-fund-switch-lag-buy-list';
	public static readonly FIND_FUND_SWITCH_LAG_RESULT= '/find-fund-switch-lag-result';
	public static readonly GET_FUND_SELECTOR_RESULTS = '/get-fund-selector-results';
	public static readonly GET_FUND_ASSET_CLASS = '/get-fund-asset-class';
  public static readonly FIND_FUND_BY_MAIN_FILTER: string = '/find-fund-by-main-filter-category-map';
	public static readonly GET_FSM_INDICES_FACTSHEET: string = '/get-fsm-indices-factsheet';
	public static readonly GET_FUND_INDEX_FACTSHEET: string = '/get-fund-index-factsheet';
	public static readonly GET_FUNDS_LOC = '/get-funds-loc';
	public static readonly GET_SECTOR_PERFORMANCE_BY_SEDOL_LIST = '/get-sector-performance-by-sedol-list';
	public static readonly FIND_EXCHANGE_HOLIDAYS = '/find-exchange-holidays';
	public static readonly GET_DISTINCT_EXCHANGE_HOLIDAY_TYPE = '/get-distinct-exchange-holiday-type';
	public static readonly GET_FUND_UPDATE_DATE = '/get-fund-updated-date';
  public static readonly FIND_ALL_BUYABLE_OR_RSPABLE_PRODUCT_ID = '/find-all-buyable-or-rspable-product-id';
  public static readonly GET_FUND_DETAILS = '/get-fund-details';
  public static readonly GET_FUND_PAYMENT_MODE = '/get-fund-payment-mode';
  public static readonly GET_RSP_PAYMENT_MODE = '/get-rsp-payment-mode';
  public static readonly FIND_ALL_TRADING_CURRENCY = '/find-all-trading-currency';

  public static readonly GET_INVEST_AMOUNT_FX = '/get-invest-amount-fx';
  public static readonly CREATE_ORDER = '/create-order';
  public static readonly VALIDATE_ORDER = '/validate-order';
  public static readonly GET_AMG_LOC_LIST = '/get-amg-loc-list';
  public static readonly GET_ALL_BUY_RSP_FUND_LIST = '/get-all-buy-rsp-fund-list';
  public static readonly GET_BUY_RSP_FUND_LIST = '/get-buy-rsp-fund-list';
  public static readonly FIND_ALL_SELL_SWITCH_FUND = '/find-all-sell-switch-fund';
  public static readonly FIND_ALL_SWITCHABLE_FUND = '/find-all-switchable-fund';
  public static readonly FIND_SELL_SWITCH_FUND_BY_PRODUCT_ID = '/find-all-sell-switch-fund';
  public static readonly GET_FUND_REDEMPTION_TRADE_DATE  = '/get-fund-redemption-trade-date';
  public static readonly GET_FUND_SWITCH_TRADE_DATE = '/get-fund-switch-trade-date';
  public static readonly GET_SWITCH_TYPE_DETAILS = '/get-switch-type-details';
  public static readonly GET_REDEMPTION_METHODS = '/get-redemption-methods';
  public static readonly FIND_ALL_SWITCHABLE_PRODUCT_ID = '/find-all-switchable-product-id';
  public static readonly GET_MAX_AMF_REBATE_STATEMENT = '/get-max-amf-rebate-statement';
	public static readonly GET_BID_PRICE_CHART_DATA_IN_PERCENTAGE: string = '/get-bid-price-chart-data-in-percentage';

  //FUND RSP
  public static readonly VERIFY_UPDATE_RSP_UT_AMOUNT = '/verify-update-rsp-ut-amount';
  public static readonly UPDATE_RSP_UT_AMOUNT = '/update-rsp-ut-amount';
  public static readonly UPDATE_RSP_FUND_DDA = '/update-rsp-fund-dda';
  public static readonly TERMINATE_RSP_UT_FUND = '/terminate-rsp-ut-fund';
  public static readonly GET_UT_RSP_INFO_LIST = '/get-ut-rsp-info-list';
  public static readonly GET_ALL_DDA_RECORD_BY_REFNO = '/get-all-dda-record-by-refno';
  public static readonly GET_RSP_DETAILS = '/get-rsp-details';
  public static readonly CHECK_RSP_FREEZING_PERIOD = '/check-rsp-freezing-period';
  public static readonly GET_AVAILABLE_PAYMENT_METHOD_TO_AMEND = '/get-available-payment-method-to-amend';
  public static readonly GET_RSP_INFO_LIST = '/get-rsp-info-list';

  //FUND TRANSFER
  public static readonly TRANSFER_IN_UT = '/transfer-in-ut';

  //BOND
  public static readonly BOND_IPO_LIST: string = '/find-all-bond-ipo';
  public static readonly BOND_NEWLY_LIST: string = '/find-all-bond-newly';
  public static readonly BOND_EXPRESS_LIST = "/find-all-bond-express";
  public static readonly BOND_EXCHANGE_LIST = "/find-all-bond-exchange";
  public static readonly BOND_SELECTOR: string = '/bond-selector';
  public static readonly INIT_BOND_SELECTOR: string = '/init-bond-selector';
  public static readonly BOND_SELECTOR_RESULT: string ='/bond-selector-result';
  public static readonly BOND_CHART_SELECTOR_RESULT: string ='/bond-chart-selector-query';
  public static readonly SELECTOR_RESULTS_PAGINATION: string = '/selector-results/by-page';
  public static readonly SELECTOR_OPTIONS: string = '/selector-options';
  public static readonly BOND_MGS_LIST: string = '/bond-mgs-list';
  public static readonly BOND_CALCULATOR_DATA_BY_SETTLEMENT_DATE = "/bond-calculator-data-by-settlement-date";
  public static readonly BOND_ACTIVE_LIST = "/bond-active-list";
  public static readonly BOND_EXPRESS_CHECK_BUSINESS_HOURS: string = "/check-is-bond-express-business-hours";
  public static readonly BOND_RECOMMENDED_LIST = "/bond-recommended-list";
  public static readonly BOND_FACTSHEET = '/bond-factsheet';
  public static readonly YIELD_CURVE = '/yield-curve';
  public static readonly YIELD_CURVE_MATURITY = '/yield-curve-maturity';
  public static readonly MARKET_DATA_INDEX_CHART = '/market-data-index-chart';
  public static readonly MARKET_DATA_INDEX_CHART_GRAPH = '/market-data-index-chart-graph';
  public static readonly SECTOR_LIST = '/sector-list';
  public static readonly TOP_LIST = '/top-list';
  public static readonly BEST_WORST_CHART_DATA = '/best-worst-chart-data';
  public static readonly CHART_CENTRE_BOND_PERFORMANCE_CHART_DATA = '/bond-chart-center-performance-data';
  public static readonly FIND_ALL_BUY_MARKUP_RATE = '/find-all-buy-markup-rate';
  public static readonly DOCUMENT_LIST = '/document-list';
  public static readonly PENDING_BUY = '/pending-buy';
  public static readonly BOND_SELL_CONTRACT = '/bond-sell-contract';
  public static readonly BOND_CHART_CENTRE_DATA = '/bond-chart-center-data';
  public static readonly BOND_MARKET_TYPE_LIST = '/bond-market-list';
  public static readonly BOND_LATEST_ASK_BID_PRICE = '/get-latest-ask-bid-price';
  public static readonly BOND_EXPRESS = '/bond-express';
  public static readonly BOND_YIELD_CURVE_CHART = '/get-bond-yield-curve-chart';
  public static readonly BOND_YIELD_CURVE_MATURITY_CHART = '/get-bond-yield-curve-maturity-chart';
  public static readonly BOND_INDEX_DATA = '/get-bond-index-data';
  public static readonly BOND_INDEX_CHART = '/get-bond-index-chart';
  public static readonly BOND_SECTOR = '/find-bond-best-worst-sectors';
  public static readonly BOND_BEST_WORST_LIST= '/find-bond-best-worst-list';
  public static readonly BOND_LIST_CHART= '/find-list-of-bond-chart';
  public static readonly BOND_LIST= '/find-list-of-active-bonds';
  public static readonly BOND_MARKET_LIST= '/get-bond-market-list';
  public static readonly BOND_CALCULATE= '/calculate-bond-result';
  public static readonly BOND_PERFORMANCE_LIST= '/find-bond-performance';
  public static readonly BOND_FACTSHEET_DATA= '/find-bond-factsheet';
  public static readonly BOND_EXPRESS_DATA= '/is-bond-express';
  public static readonly BOND_PROCESSING_FEES= '/bond-processing-fee';
  public static readonly GET_BOND_PROCESSING_FEES = '/get-bond-processing-fee';
  public static readonly PRELAUNCH_BOND_INFO = '/get-prelaunch-bond-info';
  public static readonly PRELAUNCH_BOND_INFO_FEATURE_DISCLAIMER = '/get-prelaunch-bond-feature-disclaimer';
  public static readonly BOND_FEATURE_DISCLAIMER = '/get-bond-feature-disclaimer';
  public static readonly BOND_CREDIT_RATING_CHOICES = '/find-bond-credit-rating-choices';
  public static readonly BOND_FACTSHEET_CHART = '/find-bond-factsheet-chart';
  public static readonly FX_BUFFER = '/get-fx-buffer';
  public static readonly BOND_INTRADAY_CHART = '/find-bond-intraday-chart';

  public static readonly FIND_BOND_TRADE_CURRENCY = '/find-bond-trade-currency';
  public static readonly SELECT_BOND_INFO_LIST = '/get-select-bond-list';
  public static readonly SEARCH_SELECT_BOND_LIST = '/search-select-bond-list';
  public static readonly GET_BUY_SELL_BOND_DISP = '/get-buy-sell-bond-disp';
  public static readonly GET_BOND_RISK_RATING = '/get-bond-risk-rating';
  public static readonly GET_BOND_AVAIL_TRUST_ACCOUNT_PAYMENT_MODE_DISP_LIST = '/get-bond-available-trust-account-payment-mode-disp-list';
  public static readonly VALIDATE_BOND_BUY = '/validate-bond-buy';
  public static readonly CHECK_COMPLEX_BOND = '/is-bonds-complex-product';
  public static readonly CHECK_SPECIAL_FEATURE_BOND_BY_ISSUE_CODE = '/check-is-special-feature-bond-by-issue-code';
  public static readonly GET_SPECIAL_FEATURE_BOND_DISP = '/get-bond-feature-disclaimer-disp';
  public static readonly GET_PRELAUNCH_BOND_FEATURE_DISP = '/get-prelaunch-bond-feature-disclaimer-disp';
  public static readonly CHECK_IS_NORMAL_BOND = '/check-is-normal-bond';
  public static readonly CHECK_BOND_QUESTIONAIRE_ANSWER = '/check-questionaire-answer';
  public static readonly GET_ACKNOWLEDGE_RISK_STATEMENT = '/get-acknowledge-risk-statement';
  public static readonly PRE_CHECK_BOND_BUY_SELL = '/bond-buy-sell-precheck';
  public static readonly BOND_CONFIRM_TRADE = '/confirm-trade';
  public static readonly BOND_EXPRESS_BUY_CONFIRM_TRADE = '/submit-buy-it-now-order';
  public static readonly BOND_EXPRESS_SELL_CONFIRM_TRADE = '/submit-sell-it-now-order';
  public static readonly BOND_EDIT_TRADE = '/edit-trade';
  public static readonly BOND_VOID_TRANSACTION = '/void-transaction';

	public static readonly VALIDATE_BOND_IPO_SUBSCRIPTION = '/validate-prelaunch-bond-subscription';
	public static readonly BOND_EXPRESS_JSON = '/bondexpress.json';
	public static readonly BOND_TOP_VOL ='/get-bond-top-volume';
  public static readonly GET_BOND_INFO ='/get-bond-info';
	public static readonly BOND_HIGHEST_YIELD = '/find-highest-yielding-bond';
	public static readonly BOND_SOVEREIGN_BOND_COUNTRY_REGION = '/find-sovereign-bond-country-region';
	public static readonly FIND_BOND_PENDING_BUY_TRANSACTIONS = '/find-bond-pending-buy-transactions';
  public static readonly FIND_BOND_PENDING_SELL_TRANSACTIONS = '/find-bond-pending-sell-transactions';
  public static readonly GET_LATEST_BOND_ISSUES = '/get-latest-bond-issues';
  public static readonly FIND_BOND_IPO_SUBSCRIPTION = '/find-bond-ipo-subscription';
  public static readonly FIND_BOND_IPO_ALLOTMENT = '/find-bond-ipo-allotment';
  public static readonly GET_PRELAUNCH_BOND_INFO_BY_PRELAUNCH_ID = '/get-prelaunch-bond-info-by-prelaunch-id';
  public static readonly GET_BOND_TRADE_CURRENCY_CODE_LIST = '/get-bond-trade-currency-code-list';
  public static readonly GET_PRELAUNCH_BOND_TOTAL_PURCHASE_AMOUNT = '/get-prelaunch-bond-total-purchase-amount';
  public static readonly SUBMIT_PRELAUNCH_BOND_SUBSCRIPTION = '/submit-prelaunch-bond-subscription';
  public static readonly CHECK_IS_INSUFFICIENT_AUM = '/check-is-insufficient-aum';
  public static readonly FIND_BOND_RECEIVED_BUY_TRANSACTIONS = '/find-bond-received-buy-transactions';
  public static readonly FIND_BOND_RECEIVED_SELL_TRANSACTIONS = '/find-bond-received-sell-transactions';
  public static readonly FIND_PRELAUNCH_BOND_SUBSCRIPTION_PENDING_TRANSACTIONS = '/find-prelaunch-bond-subscription-pending-transaction';
  public static readonly SEND_NEW_BOND_ISSUE_SUBSCRIPTION_FORM = '/send-new-bond-issue-subscription-form'
  public static readonly GET_TREASURY_NOW_BOND_YIELD_CURVE_CHART_DATA = '/get-treasury-now-bond-yield-curve-chart-data';

  //Bond Transfer
  public static readonly TRANSFER_IN_BOND ='/transfer-in-bond';

	// Bond Corporate Action
	public static readonly FIND_BOND_CORPORATE_ACTIONS = '/find-bond-corporate-actions';
	public static readonly GET_BOND_CA_FILE_LIST = '/get-bond-ca-file-list';
	public static readonly DOWNLOAD_BOND_CA_FILE = '/download-bond-ca-file';
	public static readonly CREATE_CORPORATE_ACTION_INSTRUCTION = '/create-corporate-action-instruction';
	public static readonly FIND_CA_FORM_OPTIONS_BY_CA_ID = '/find-ca-form-options-by-ca-id';
	public static readonly GET_CA_INSTRUCTION_DETAILS = '/get-ca-instruction-details';

   //Stock Transfer
   public static readonly TRANSFER_IN_STOCK ='/transfer-in-stock';

  // DPMS
  public static readonly GET_DPMS_PORTFOLIO_DETAILS: string = "/get-portfolio-details";
  public static readonly GET_DPMS_MONTE_CARLO_PERFORMANCE: string = "/get-monte-carlo-performance";
  public static readonly GET_DPMS_PORTFOLIO_BEST_PERFORMANCE_FUND: string = '/find-portfolio-best-performance-fund';
  public static readonly GET_DPMS_PORTFOLIO_WORST_PERFORMANCE_FUND: string = '/find-portfolio-worst-performance-fund';
  public static readonly GET_DPMS_PORTFOLIO_IDS: string = '/get-dpms-portfolio-ids';
  public static readonly FIND_PORTFOLIO_MATERIALS_NO_LOGIN: string = '/find-portfolio-materials-no-login';
  public static readonly GET_DPMS_PORTFOLIO_BEST_PERFORMANCE_FUND_RANKING: string = '/find-portfolio-best-performance-fund-ranking';
  public static readonly GET_DPMS_PORTFOLIO_WORST_PERFORMANCE_FUND_RANKING: string = '/find-portfolio-worst-performance-fund-ranking';
  public static readonly FIND_PORTFOLIO: string = '/find-portfolio';
  public static readonly GET_PORTFOLIO_INFO: string = '/get-portfolio-info';
  public static readonly GET_PORTFOLIO_PERFORMANCE: string = '/get-dpms-portfolio-performance';
  public static readonly GET_PORTFOLIO_DAILY_AUM: string = '/get-portfolio-daily-aum';
  public static readonly GET_BENCHMARK_PORTFOLIO_DAILY_AUM: string = '/get-benchmark-portfolio-daily-aum';
  public static readonly GET_ALL_PORTFOLIO_ANNUALISED_RETURNS: string = '/get-all-annualised-returns';

  //Careers
  public static readonly FIND_CAREERS_LIST: string = '/find-latest-careers-list';
  public static readonly GROUP_BY_DEPARTMENT: string = '/group-by-department';

  public static readonly GET_FUND_HOUSE_DATA: string = '/get-fund-managers-and-funds-total-list';
  public static readonly GET_FUND_HOUSE_DETAILS_DATA: string = '/get-fund-managers-details'

  //Banner
  public static readonly BANNER: string = '/banner';
  public static readonly FIND_BANNER_AD: string = '/find-banner-ad';
  public static readonly GET_BANNER_LIST: string = '/get-banner-list';
  public static readonly FIND_FUND_NOTICE_BOARD_LIST: string = '/find-fund-notice-board-list';
  public static readonly FIND_DPMS_NOTICE_BOARD_LIST: string = '/find-dpms-notice-board-list';
  public static readonly FIND_BOND_NOTICE_BOARD_LIST: string = '/find-bond-notice-board-list';
  public static readonly FIND_STOCK_NOTICE_BOARD_LIST: string = '/find-stock-notice-board-list';
  public static readonly FIND_MENU_BANNER_LIST: string = '/find-menu-banner-list';
  public static readonly GET_MAINTENANCE_ALERT: string = '/get-maintenance-alert';

  //Portfolio Simulator
  public static readonly GET_FUND_LIST: string = '/get-fund-list';
  public static readonly GET_PORTFOLIO_CHART_DATA: string = '/get-portfolio-chart-data';
  public static readonly GET_FUND_DATA: string = '/get-fund-data';

  //Chart Centre
  public static readonly GET_ACTIVE_FUND_LIST: string = '/get-all-active-fund-list-for-chart-center';

  public static readonly GET_MAIN_SECTOR_LIST: string = '/get-mainsectorcode-loc-list';

  public static readonly GET_SPEC_SECTOR_LIST: string = '/get-specsectorcode-list';

  public static readonly GET_AREA_SECTOR_LIST: string = '/get-areasectorcode-loc-list';

  public static readonly GET_FUND_HOUSES_LIST: string = '/get-all-fund-manager-for-chart-center';

  public static readonly GET_PRS_LIST: string = '/get-prs-data-for-chart-center';

  public static readonly GET_CHART_TABLE_DATA: string = '/get-chart-table-data';

  public static readonly GET_BID_PRICE_CHART_DATA_MOVING_AVERAGE: string = '/get-bid-price-chart-data-for-moving-average';

  //Login
  public static readonly LOGIN: string = '/login';
  public static readonly GET_URL: string = '/get-url';
  public static readonly GET_PRESESSION: string = '/get-presession';
  public static readonly VALIDATE_PASSWORD: string = '/validate-password';
  public static readonly INVALIDATE_TOKEN: string = '/invalidate-token';
  public static readonly FSMONE_LOGIN: string = '/rest-api/fsmoneLogin';
  public static readonly GET_AUTHENTICATION: string = '/get-authentication';

  //Holding
  public static readonly GET_QUESTION_ANSWER: string = '/question-answer/read';
  public static readonly CASH_FUND: string = '/cash-fund';
  public static readonly RISK_PROFILE: string = '/risk-appetite';
  public static readonly RISK_PROFILE_VALIDATION: string = '/client-risk-profile-completion/validate';
  public static readonly RISK_PROFILE_CALCULATE: string = '/risk-profiling-result/calculate';
  public static readonly RISK_PROFILE_SAVE: string = '/risk-profiling-result/save';
  public static readonly ASSEST_ALLOCATION: string = '/asset-allocation';
  public static readonly PORTFOLIO_COMMENT: string = '/portfolio-comment';
  public static readonly HISTORICAL_RETURN: string = '/historical-return';
  public static readonly ABSOLUTE_RETURN: string = '/absolute-return';
  public static readonly ABSOLUTE_READ: string = '/absolute-read';

  //Transaction
  public static readonly GET_FUNDS_BUY_LISTING_DATA: string = '/buy-filter-listing';
  public static readonly GET_ALL_FUND_LIST: string = '/buy-listing-data';
  public static readonly GET_CASH_PAYMENT_METHODS: string = '/buy/lumpsum-details/read';
  public static readonly GET_PRS_NOTICE: string = '/prs/notice/read';
  public static readonly CONFIRM_ORDER: string = '/confirm-order'
  public static readonly GET_FORM_SAMPLE: string = '/form/read'
  public static readonly GET_BUY_SALES_CHARGE: string = '/buy/sales-charge/read';
  public static readonly GET_BUY_PENDING_TRANSACTION: string = '/pending/read';
  public static readonly GET_SELL_HOLDING: string = '/sell/holding/read'
  public static readonly DOWNLOAD_ORDER_NO_FORM: string = '/transaction-form/read'
  public static readonly GET_SWITCH_BUY_LIST: string = '/switch/buy/listing/read';
  public static readonly GET_SWITCH_SALES_CHARGE: string = '/switch/sales-charge/read';
  public static readonly GET_INTRA_WAIVE: string = '/switch/mass-check-intra-waive/read';
  public static readonly VALIDATE_SELL: string = '/sell/validate';
  public static readonly VALIDATE_SWITCH: string = '/switch/validate';
  public static readonly CART: string = '/cart';
  public static readonly BUY_LIST: string = '/buy-list';
  public static readonly BUY_DETAILS: string = '/buy-details';
  public static readonly HOLDING_LIST: string = '/holding-list';
  public static readonly COOLING_OFF_READ: string = '/cooling-off/available/read';
  public static readonly COOLING_OFF_CREATE: string = '/cooling-off/create';
  public static readonly PROCESSING_FEE: string = '/processing-fee';
  public static readonly TOTAL_AMOUNT: string = '/total-amount';
  public static readonly TOTAL_AMOUNT_FX: string = '/total-amount-fx';
  public static readonly TOTAL_SALE_AMOUNT_FX: string = '/total-sale-amount-fx';
  public static readonly PAYMENT_METHOD_LIST: string = '/payment-method-list';
  public static readonly GET_MASS_SWITCH_SALES_CHARGE: string = '/mass-sales-charge';
  public static readonly GET_PRS_FIRST_BUY_AVAILABLE: string = '/prs/first-buy/available';


  //Insurance
  public static readonly FIND_TERM_LIFE_PRODUCT_PLAN_CARDS: string = '/find-term-life-product-plan-cards';
  public static readonly FIND_MEDICAL_HEALTH_PRODUCT_PLAN_CARDS: string = '/find-medical-health-product-plan-cards';
  public static readonly FIND_TRAVEL_PRODUCT_PLAN_CARDS: string = '/find-travel-product-plan-cards';
  public static readonly GET_RECOMMENDED_INSURANCE_PLANS: string = '/get-recommended-insurance-plans';
  public static readonly GET_CARRIER_LIST: string = '/get-insurance-carrier-list';
  public static readonly GET_INSURER_INFO: string = '/get-selected-insurer-info';
  public static readonly GET_TERM_LIFE_PLAN_INFO: string = '/get-term-life-plan-info';
  public static readonly SENIOR_LIFE_CARE_PLAN_INFO: string = '/senior-life-care-plan-info';
  public static readonly CRITICAL_ILLNESS_CARE_PLAN_INFO: string = '/critical-illness-plan-info';
  public static readonly GET_MEDICAL_CARD_PLAN_INFO: string = '/get-medical-card-plan-info';
  public static readonly GET_MANU_PROTECT_PLAN_INFO: string = '/get-manu-protect-plan-info';
  public static readonly GET_MCIS_LEVEL_TERM_PLAN_INFO: string = '/get-mcis-level-term-plan-info';
  public static readonly GET_MEDICAL_AXA_PLAN_INFO: string = '/get-medical-axa-plan-info';
  public static readonly GET_MEDICAL_MANU_EZ_PLAN_INFO: string = '/get-medical-manu-ez-plan-info';
  public static readonly GET_TRAVEL_COUNTRY_LIST: string = '/get-travel-country-list';
  public static readonly GET_TRAVEL_REGION_LIST: string = '/get-travel-region-list';
  public static readonly GET_TRAVEL_QUOATION_ID: string = '/get-travel-quotation-id';
  public static readonly GET_TRAVEL_LANDING_URL: string = '/get-travel-landing-url';
  public static readonly GET_MANU_PROTECT_QUOTATION: string = '/get-manu-protect-quotation';
  public static readonly GET_MCIS_LEVEL_TERM_QUOTATION: string = '/get-mcis-level-term-quotation';
  public static readonly GET_MANU_EZ_QUOTATION: string = '/get-manu-ez-quotation';
  public static readonly GET_MANU_PROTECT_ONLINE_APPLICATION: string = '/get-manu-protect-apply-application';
  public static readonly GET_MCIS_LEVEL_TERM_APPLY_APPLICATION: string = '/get-mcis-level-term-apply-application';
  public static readonly GET_MANU_EZ_MED_ONLINE_APPLICATION: string = '/get-manu-ez-med-apply-application';
  public static readonly GET_MANU_PROTECT_APPLY_URL: string = '/get-manu-protect-apply-url';
  public static readonly GET_AXA_URL: string = '/get-axa-medic-url';
  public static readonly SEND_EMAIL_TO_CLIENT: string = '/send-email-to-client';
  public static readonly SHOW_SELECTION_RESULT: string = '/show-selection-result';
  public static readonly GET_ALL_PRODUCT_NAME: string = '/get-all-product-name';
  public static readonly SUBMIT_QUOTATION_FORM: string = '/submit-quotation-form';
  public static readonly GET_ALL_BANNERS: string = '/get-all-banners';
  public static readonly FIND_CLIENT_POLICIES_LIST: string = '/find-client-policies-list';
  public static readonly FIND_POLICY_DETAIL: string = '/find-policy-detail';
  public static readonly GET_ALL_PLAN_TYPE_DESC: string = '/get-all-plan-type-desc';

  // FAQ
  public static readonly GET_FAQ_L1_CATEGORY = '/get-lvl1-categories';
  public static readonly GET_FAQ_L2_CATEGORY = '/get-lvl2-categories';
  public static readonly GET_FAQ_L3_CATEGORY = '/get-lvl3-categories';
  public static readonly FIND_FAQ_QA = '/find-question-and-answer';
  public static readonly GET_GLOSSARY = '/get-glossary';


  // Product
  public static readonly KEYWORD_SEARCH: string = '/keyword-search';
  public static readonly SEARCH_PRODUCTS_BY_KEYWORD: string = '/search-products-by-keyword';
	public static readonly SEARCH_PRODUCTS_BY_TYPE_KEYWORD = '/search-products-by-product-type-and-keyword';
  public static readonly SEARCH_PRODUCTS_BY_KEYWORD_EXCHANGE = '/search-products-by-product-type-and-exchange';
  public static readonly SEARCH_PRODUCTS_FOR_WATCHLIST = '/search-products-for-watchlist';
  public static readonly SEARCH_PRODUCTS_BY_KEYWORD_EXCHANGE_MV = '/search-products-by-product-type-and-exchange-mv';
  public static readonly RECENT_SEARCH_ADD = '/recent-search/add';
  public static readonly RECENT_SEARCH_FIND = '/recent-search/find';
  public static readonly RECENT_SEARCH_UPDATE = '/recent-search/update';
  public static readonly TRENDING_SEARCH = '/trending-search';

  // Article Search
  public static readonly SEARCH_ARTICLES_BY_KEYWORD = '/search-articles-by-keyword';

  // Authority
  public static readonly MOBILE_PHONE_CODE = '/mobile-phone-code';
  public static readonly PUBLIC = '/public';

  // Payment Slip
  public static readonly GET_AVAILABLE_BANK_IN_FROM = '/available-bank-in-from/read';
  public static readonly GET_AVAILABLE_BANK_IN_TO = '/available-bank-in-to/read';
  public static readonly GET_AVAILABLE_PAYMENT_METHOD = '/payment-method/read';
  public static readonly CREATE_PAYMENT_SLIP = '/protected/create';

  // Payment
  public static readonly FPX = '/fpx';
  public static readonly BANK_STATUS = '/bank-status';
  public static readonly DIRECT_UPDATE = '/direct-update';
  public static readonly NEW_CERT = '/new-cert';
  public static readonly PRE_SUBMIT = '/pre-submit';
  public static readonly EMANDATE_DETAILS = '/emandate-details';

  // PRS
  public static readonly GET_ACCOUNT_INFORMATION = '/account-information/read';
  public static readonly UPDATE_ACCOUNT_ADDITIONAL_INFO_AND_DOC = '/account-additional-info-and-supporting-doc/update';
  public static readonly REQUIRED_FIELD = '/required-field';

  // Research
  public static readonly FIND_REGIONAL_MARKET_SECTOR_REVIEW = '/find-regional-market-sector-review';
  public static readonly FIND_SINGLE_COUNTRY_SECTOR_REVIEW = "/find-single-country-sector-review";
  public static readonly GET_ENEWSLETTER_INFO = '/find-enewsletter-info';
  public static readonly UNSUBSCRIBE_ENEWSLETTER = '/unsubscribe-enewsletter';

  // trust account
  public static readonly FIND_LATEST_TRUST_ACCOUNT_INTEREST_RATE = '/latest-trust-bank-rate/read';
  public static readonly TRADABLE_CURRENCIES = '/tradable-currencies';
  public static readonly TRANSACTION_LIST_NET_AMOUNT = '/transaction-list-with-net-amount';
  public static readonly TRANSFER_FX = '/transfer-fx';
  public static readonly CONSOLIDATED_AND_ACTIVE_LIST = '/consolidated-and-active-list';
  public static readonly CROSS_ACCOUNT_TRANSFER = '/cross-account-transfer';
  public static readonly LATEST_TRUST_ACCOUNT_INTEREST_RATE = '/latest-trust-bank-rate';

//Foreign Exchange
  public static readonly ALL_RATES = '/all-rates';
  public static readonly SCALE_FX_RATE_INFO = '/scale-fx-rate-info';
  public static readonly FX_RATING = '/fx-rating';

  // Settlement
  public static readonly UT_BUY_SETTLEMENT_BANK_DETAILS = '/ut/buy/settlement-bank-details/read';

  // Authority
  public static readonly TOKEN: string = '/token';
  public static readonly LOGOUT: string = '/logout';

  // OTP
  public static readonly TWO_FA_ACCOUNT = '/two-fa-account'

  //Transaction Record
  public static readonly HISTORICAL = '/historical';

  // Referral Channel
  public static readonly GOPAYZ: string = '/gopayz';

  // Shariah Flag
  public static readonly STOCKS_TICKER_INFO_DETAILS: string = '/stock-data/ticker-info-details';
  public static readonly STOCKS_TICKER_INFO_SHARIAH_DETAILS: string = '/stock-data/ticker-info-shariah-details';

  //Leveraged and Inverse stock
  public static readonly STOCKS_TICKER_INFO_LEVERAGED_INVERSE: string = '/stock-data/ticker-info-leveraged-inverse';

  //stocks screener
  public static readonly STOCKS_SCREENER: string = '/stock-info/stock-screener';
  public static readonly STOCKS_GET_SCREENER_PARAM: string = '/stock-info/get-screener-param';
  public static readonly STOCKS_SCREENER_BY_SECTOR: string = '/stock-data/stock-screener-by-sector';
  public static readonly STOCKS_SCREENER_CURRENCY_LIST: string = '/stock-data/stock-screener-currency-list';

  //stock corporate action
  public static readonly STOCKS_CA_UPCOMING_DIVIDEND: string = '/corporate-action/upcoming-dividend';
  public static readonly STOCKS_CORPORATE_ACTION_HKEX: string = '/corporate-action/hkex-upcoming-dividend';
  public static readonly STOCKS_CORPORATE_ACTION_RESPONSE: string = '/protected/corporate-action/client-actionable';
  public static readonly STOCKS_CORPORATE_ACTION_RESPONSE_SUBSCRIPTION_LIST: string = '/protected/corporate-action/client-actionable/subscription';
  public static readonly STOCKS_CORPORATE_ACTION_RESPONSE_OPTION_LIST: string = '/protected/corporate-action/client-actionable/option';
  public static readonly STOCKS_CORPORATE_ACTION_RESPONSE_HISTORY: string = '/protected/corporate-action/client-actionable/subscription-history';
  public static readonly STOCKS_COMPANY_ANNOUNCEMENT: string = '/company-announcement';

  //stocks factsheets
  public static readonly STOCKS_TICKER_INFO: string = '/stock-data/ticker-info';
  public static readonly STOCKS_TICKER_INFO_BY_EXCHANGE_STOCK_CODE: string = '/stock-data/ticker-info-by-exchange-stock-code';
  public static readonly STOCKS_TICKER_PRICE_BY_STOCK_CODE: string = '/get-ticker-and-price-by-stock-code';
  public static readonly ETF_TICKER_INFO: string = '/etf-data/etf-ticker-info';
  public static readonly STOCKS_COMPANY_INFO: string = '/stock-data/company-info';
  public static readonly STOCKS_INCOME_STATEMENT: string = '/stock-data/income-statement';
  public static readonly STOCKS_CASH_FLOW: string = '/stock-data/cash-flow';
  public static readonly STOCKS_BALANCE_SHEET: string = '/stock-data/balance-sheet';
  public static readonly STOCKS_GROWTH_RATIO: string = '/stock-data/growth-ratio';
  public static readonly STOCKS_EFFICIENCY_RATIO: string = '/stock-data/efficiency-ratio';
  public static readonly STOCKS_FINANCIAL_HEALTH_RATIO: string = '/stock-data/financial-health-ratio';
  public static readonly STOCKS_PROFITABILITY: string = '/stock-data/profitability';
  public static readonly STOCKS_10_YEAR_GOVERNMENT_BOND_YIELD_PERCENT: string = '/get-ten-year-government-bond-yield-percent-by-country';
  public static readonly STOCKS_RETURN_STATISTICS: string = '/get-return-statistics-by-ticker-no';
  public static readonly STOCKS_INDUSTRY_PEERS: string = '/stock-data/industry-peers';
  public static readonly STOCKS_VALUATION_RATIO: string = '/get-valuation-ratio';
  public static readonly STOCKS_DIVIDEND_DATA: string = '/stock-data/dividend-data';
  public static readonly STOCKS_CONSENSUS_ESTIMATES: string = '/stock-data/consensus-estimates';
  public static readonly STOCKS_DIVIDEND_YIELD_PAYOUT_RATIO: string = '/stock-data/dividend-yield-payout-ratio';
  public static readonly STOCKS_GET_PRICE_QUOTE: string = '/get-price-quote';
  public static readonly STOCK_MARKET_PERFORMANCE_DETAIL: string = '/stock-data/get-market-performance-detail';
  public static readonly STOCK_BURSA_SECTOR_PERFORMANCE: string = '/stock-data/sector-performance';
  public static readonly STOCKS_QUOTE_TICKER_NOS: string = '/stock-data/stock-quote-ticker-nos';
  public static readonly STOCKS_BUSINESS_DESCRIPTION: string = '/stock-data/business-description';
  public static readonly STOCKS_CURRENT_MARKET_CAP: string = '/stock-data/current-market-capitalisation';
  public static readonly STOCKS_INSIDER_SUMMARY: string = '/stock-data/insider-summary';
  public static readonly STOCKS_INSTITUTIONAL_HOLDING_SUMMARY: string = '/stock-data/institutional-holding-summary';
  public static readonly STOCKS_INSTITUTIONAL_HOLDING_DETAILS: string = '/get-institutional-holdings-detail-by-ticker-no';
  public static readonly STOCKS_FUND_HOLDING_DETAILS: string = '/get-fund-holdings-detail-by-ticker-no';
  public static readonly STOCKS_INSIDER_DETAILS: string = '/stock-data/insider-details';
  public static readonly STOCKS_TRAILING_MARKET_RETURN: string = '/stock-data/trailing-market-return';
  public static readonly STOCKS_COMPANY_FINANCIAL_AVAILABILITY_LIST: string = '/stock-data/company-financial-availability-list';
  public static readonly STOCKS_TICKER_AND_PRICE_INFO: string = '/stock-data/ticker-and-price-info';
  public static readonly STOCKS_GET_CHART_DATA: string = '/get-chart-data';
  public static readonly STOCKS_SERVER_TIME: string = '/stock-data/server-time';
  public static readonly STOCKS_EXCHANGE: string = '/exchange';
  public static readonly STOCKS_FINANCIAL_TEMPLATE_CONFIG: string = '/get-financial-template-mapping';
  public static readonly STOCK_ETF_PERFORMANCE: string = '/stock-data/etf-performance';
  public static readonly STOCK_ETF_PERFORMANCE_LIST: string = '/stock-data/etf-performance-list';
  public static readonly STOCKS_ETF_PERFORMANCE_CHART_DATA: string = '/stock-data/get-etf-performance-chart-data';
  public static readonly STOCKS_ETF_BID_PRICE_CHART_DATA: string = '/stock-data/get-etf-bid-price-chart-data';
  public static readonly STOCKS_ETF_MOVING_AVE_CHART_DATA: string = '/stock-data/get-etf-moving-ave-chart-data';
  public static readonly STOCKS_ETF_PERFORMANCE_DATA: string = '/stock-data/get-etf-performance-data';
  public static readonly STOCKS_ETF_CALENDAR_RETURN: string = '/stock-data/get-etf-calendar-year-return';
  public static readonly STOCKS_ETF_RETURN_DATE_RANGE: string = '/stock-data/get-etf-return';
  public static readonly STOCK_ETF_TOP_HOLDING: string = '/stock-data/etf-top-holding';
  public static readonly STOCKS_MOST_ACTIVE: string = '/stock-data/most-active';
  public static readonly STOCKS_TOP_GAINER: string = '/stock-data/top-gainer';
  public static readonly STOCKS_TOP_LOSER: string = '/stock-data/top-loser';
  public static readonly STOCKS_TOP_GAINER_PERCENT: string = '/stock-data/top-gainer-percent';
  public static readonly STOCKS_TOP_LOSER_PERCENT: string = '/stock-data/top-loser-percent';
  public static readonly STOCKS_MOST_ACTIVE_STOCK: string = '/stock-data/stock-most-active';
  public static readonly STOCKS_TOP_GAINER_STOCK: string = '/stock-data/stock-top-gainer';
  public static readonly STOCKS_TOP_LOSER_STOCK: string = '/stock-data/stock-top-loser';
  public static readonly STOCKS_TOP_GAINER_PERCENT_STOCK: string = '/stock-data/stock-top-gainer-percent';
  public static readonly STOCKS_TOP_LOSER_PERCENT_STOCK: string = '/stock-data/stock-top-loser-percent';
  public static readonly STOCKS_TOP_MARKET_CAP: string = '/stock-data/top-market-cap';
  public static readonly STOCKS_SECURITY_TYPE: string = '/stock-data/security-type';
  public static readonly STOCKS_ETF_ACTIVE_LIST: string = '/stock-data/get-all-active-etf-list';
  public static readonly STOCK_TICKER_DISTRIBUTION_SETUP_DETAIL_LIST: string = "/stock-data/get-ticker-distribution-setup-detail-list";
  public static readonly STOCK_TICKER_DISTRIBUTION_SETUP_DETAIL_LIST_BY_TICKER_NO: string = "/stock-data/get-ticker-distribution-setup-detail-list-by-ticker-no";
  public static readonly STOCKS_HKEX_RANK: string = '/stock-data/get-hkex-rank';
  public static readonly STOCKS_SSE_RANK: string = '/stock-data/get-sse-rank';
  public static readonly STOCKS_SZSE_RANK: string = '/stock-data/get-szse-rank';
  public static readonly STOCKS_SGX_RANK: string = '/stock-data/get-sgx-rank';
  public static readonly STOCKS_US_RANK: string = '/stock-data/get-us-rank-by-exchange';
  public static readonly STOCKS_HSI_PRICES: string = '/stock-data/get-hsi-prices';
  public static readonly STOCKS_NDX_PRICES: string = '/stock-data/get-ndx-prices';
  public static readonly STOCKS_SAP500_PRICES: string = '/stock-data/get-sap500-prices';
  public static readonly STOCKS_PERFORMANCE_SGX: string = '/stock-data/get-market-performance-sgx';
  public static readonly STOCKS_PERFORMANCE_SSE: string = '/stock-data/get-market-performance-sse';
  public static readonly STOCKS_PERFORMANCE_SZSE: string = '/stock-data/get-market-performance-szse';
  public static readonly STOCKS_PERFORMANCE_HKEX: string = '/stock-data/get-market-performance-hkex';
  public static readonly STOCKS_PERFORMANCE_NYSE: string = '/stock-data/get-market-performance-nyse';
  public static readonly STOCKS_PERFORMANCE_AMEX: string = '/stock-data/get-market-performance-amex';
  public static readonly STOCKS_PERFORMANCE_NASDAQ: string = '/stock-data/get-market-performance-nasdaq';
  public static readonly STOCKS_PERFORMANCE_BATS: string = '/stock-data/get-market-performance-bats';
  public static readonly STOCKS_GET_CALCULATOR_RESULT: string = '/get-stock-calculator-result';
  public static readonly STOCKS_GET_TICKER_CODE_EXCHANGE: string = '/get-ticker-and-price-by-stock-code-and-exchange';
  public static readonly STOCKS_GET_STOCK_FACTSHEET_DETAIL: string = '/get-stock-factsheet-detail';
	public static readonly STOCKS_IA_PRODUCT_MASTER = '/stock-data/get-ia-product-master-by-stock-code';
  public static readonly STOCKS_GET_FINANCIAL_INFORMATION_DATA: string = '/get-financial-information-data';
	public static readonly STOCK_INFO_GET_OTHER_FEES = '/stock-data/get-other-fees-by-type';
	public static readonly STOCK_INFO_GET_HK_OTHER_FEES = '/stock-data/get-hk-other-fees-by-type-and-currency';
  public static readonly STOCK_GET_DIVIDEND_DATA_V2: string = '/get-dividend-data-v2-by-ticker-no';
  public static readonly STOCK_GET_RANK_BY_EXCHANGE_AND_TYPE: string = '/stock-data/get-rank-by-exchange-and-type';
  public static readonly STOCK_GET_COMPANY_GENERAL_INFORMATION: string =  '/get-company-general-information-by-ticker-no';
  public static readonly STOCK_GET_BUSINESS_DESCRIPTION: string =  '/get-business-description-by-ticker-no';
  public static readonly STOCK_GET_INCOME_STATEMENT: string =  '/get-income-statement-by-ticker-no';
  public static readonly STOCK_GET_DEFAULT_STOCK_LIST: string =  '/get-default-stock-list';
  public static readonly STOCK_GET_BARS: string =  '/get-bars';
  public static readonly STOCK_GET_REALIZED_PNL_DETAILS: string =  '/get-realized-pnl-details';
  public static readonly STOCK_SEARCH_FROM_TA_CHART: string =  '/search-from-ta-chart';
  public static readonly STOCK_GET_UPDATE: string =  '/get-update';
  public static readonly STOCK_GET_SYMBOL_INFO_DETAILS: string =  '/get-symbol-info-details-by-ticker-no';
  public static readonly STOCK_GET_PRICE_SPREAD: string = '/get-price-spread-by-stock-code-and-exchange'

  //STOCK_IPO
  public static readonly STOCK_IPO_LIST_ALL_IPO: string = "/list-all-ipo";
  public static readonly STOCK_IPO_LIST_ALL_IPO_WITH_NON_SUBS: string = "/list-all-ipo-with-non-subs";
  public static readonly STOCK_IPO_LIST_ACTIVE_IPO: string = "/list-active-stock-ipo";

  public static readonly STOCK_IPO_FIND_STOCK_IPO_SUBSCRIPTION: string = "/find-stock-ipo-subscription";
  public static readonly STOCK_IPO_GET_STOCK_IPO_SUBSCRIPTION_INFO: string = "/get-stock-ipo-subsription-info";
  public static readonly STOCK_IPO_SUBMIT_STOCK_IPO_SUBSCRIPTION: string = "/submit-stock-ipo-subscription";
  public static readonly STOCK_IPO_CANCEL_STOCK_IPO_SUBSCRIPTION: string = "/cancel-stock-ipo-subscription";
  public static readonly STOCK_IPO_MODIFY_STOCK_IPO_SUBSCRIPTION: string = "/modify-stock-ipo-subscription";
  public static readonly STOCK_IPO_FIND_STOCK_IPO_ALLOTMENT_RESULT: string = "/find-stock-ipo-allotment-result";

  //etf info
  public static readonly ETF_MOST_ACTIVE: string = '/stock-data/etf-most-active';
  public static readonly ETF_TOP_GAINER: string = '/stock-data/etf-top-gainer';
  public static readonly ETF_TOP_LOSE: string = '/stock-data/etf-top-loser';
  public static readonly ETF_TOP_GAINER_PERCENT: string = '/stock-data/etf-top-gainer-percent';
  public static readonly ETF_TOP_LOSER_PERCENT: string = '/stock-data/etf-top-loser-percent';
	public static readonly ETF_INFO_BY_STOCK_CODE_EXCHANGE_CODE = '/get-etf-info-by-stock-code-and-exchange-code';
	public static readonly ETF_BID_PRICE_CHART = '/get-etf-bid-price-chart-data';
	public static readonly ETF_CHART_TABLE = '/get-etf-chart-table-data';
	public static readonly ETF_PERFORMANCE_CHART = '/get-etf-performance-chart-data';
	public static readonly ETF_STOCK_QUOTE = '/get-stock-quote';
	
	//etf fee
	public static readonly ETF_SGX_FEE_DETAILS = '/get-sgx-etf-fee-details-by-currency';
	public static readonly ETF_FEE_RATE = '/get-etf-fee-rate';
	public static readonly ETF_DEFAULT_FEE_DETAILS = '/get-default-etf-fee-details';

  //etf focus list
  public static readonly ETF_FOCUS_LIST_NAME_KEY: string = "/stock-data/etf-focus-list-by-name-key";
  public static readonly ETF_FOCUS_LIST_ENABLED: string = "/stock-data/get-etf-focus-list-by-enabled";

  //etf selector
  public static readonly ETF_TYPE_LIST: string = "/stock-data/get-etf-type-list";
  public static readonly ETF_UNDERLYING_INDEX_LIST: string = "/stock-data/get-etf-underlying-index-list";
  public static readonly ETF_SELECTOR_TABLE_INFO_MULTIPLE_LIST: string = "/stock-data/get-etf-selector-table-info-with-multiple-list";
  public static readonly ETF_AMG_SELECTOR_LOC_LIST: string = "/stock-data/get-all-selector-amg-loc-list";
  public static readonly ETF_SELECTOR_QUERY: string = "/stock-data/etf-selector-query";
  public static readonly ETF_INIT_SELECTOR: string = "/init-etf-selector";
  public static readonly ETF_SELECTOR_RESULT: string = "/load-etf-selector-result";
  public static readonly ETF_ACTIVE_LIST: string = "/find-list-of-active-etfs";
  public static readonly ETF_MANAGER_LIST: string = "/find-etf-manager-list";
  public static readonly ETF_MANAGER_DETAILS: string = "/load-etf-manager-details";
  public static readonly ETF_BEST_WORST: string = "/find-etf-best-worst";
  public static readonly ETF_BEST_WORST_FILTER_LIST: string = "/find-etf-best-worst-filter-list";


  //etf best/worst performance
  public static readonly STOCKS_ETF_TOP_PERFORMANCE_SEARCH: string = '/stock-data/find-top-etf-performance-by-search-form';
  public static readonly STOCKS_ETF_MAIN_SECTOR: string = '/stock-data/get-etf-mainsectorcode-loc-list';
  public static readonly STOCKS_ETF_SPEC_SECTOR: string = '/stock-data/get-etf-specsectorcode-loc-list';
  public static readonly STOCKS_ETF_AREA_SECTOR: string = '/stock-data/get-etf-areasectorcode-loc-list';
  public static readonly STOCKS_ETF_EXCHANGE_LIST: string = '/stock-data/get-etf-exchange-list';
  public static readonly STOCKS_ETF_CURRENCY_LIST: string = '/stock-data/get-etf-currency-list';
	public static readonly ETF_CALENDAR_YEAR_RETURN = '/get-calendar-year-return';
	public static readonly ETF_DIVIDEND_DATA = '/get-divdiend-data/';
	public static readonly ETF_HISTORY_PRICE = '/get-historical-price/';
	public static readonly ETF_TOP_TICKER_ETF_PERFORMANCE = '/get-top-ticker-etf-performance-list';
	public static readonly ETF_RETURN = '/get-etf-return/';
	
  //stocks trade
  public static readonly STOCKS_FEE_FX_EXCHANGE_RATE = '/fee/fx-rate/read';
  public static readonly STOCKS_FEE_DETAILS = '/get-stock-fee-detail';
  public static readonly STOCKS_FEE_NEW_ORDER = '/protected/fee/new-order/read';
  public static readonly STOCKS_FEE_AMEND_ORDER = '/protected/fee/amend/read';

  //stocks search
  public static readonly STOCKS_SEARCH = '/search/stock/read';
  public static readonly GET_PRODUCT_TYPE_BY_MS_INSTRUMENT_ID = '/get-product-type-by-ms-instrument-id';

  //stocks holding
  public static readonly STOCKS_PORTFOLIO = '/portfolio/read';
  public static readonly STOCKS_CASH_BALANCE = '/cash-balance/read';
  public static readonly STOCKS_HOLDINGS_STOCK_CODE = '/holdings/stock-code/read';

  //stock public holiday
  public static readonly STOCKS_PUBLIC_HOLIDAYS = '/public-holidays/read/exchange';

  //stocks 101
  public static readonly STOCKS_DATA_TOP_VOLUME = '/stock-data/top-volume';
  public static readonly STOCKS_RECOMMENDED_LIST = '/stock-data/mini-find-stocks';

  //cds account
  public static readonly READ_CDS_ACCOUNT = '/protected/cds-account/read';
  public static readonly READ_CDS_EXISTING_ACCOUNT = '/cds-account/existing/read';
  public static readonly CREATE_CDS_ACCOUNT = '/protected/cds-account/create';
  public static readonly CREATE_CDS_ACCOUNT_PENDING_ACCOUNT = '/cds-account/create';
  public static readonly HAS_APPROVED_THIRD_PARTY_RELATION = '/protected/cds-account/third-party/read'
  public static readonly REJECT_APPROVED_THIRD_PARTY_RELATION = '/protected/cds-account/third-party/reject'

  //stock watchlist
  public static readonly FIND_ALL_WATCH_LIST_ITEM_BY_ACCOUNT_ID_AND_LIST_ID = '/find-all-watchlist-item-by-account-id-and-list-id';
  public static readonly CREATE_WATCH_LIST_ITEM = '/watchlist-item/create';
  public static readonly FIND_ALL_WATCH_LIST_BY_ACCOUNT_ID = '/find-all-watchlist-by-account-id';
  public static readonly FIND_STOCK_WATCH_LIST_BY_ACCOUNT_ID = '/find-stock-watchlist-by-account-id';
  public static readonly ADD_STOCK_RECENT_SEARCH_HISTORY = '/add-recent-search-history';
  public static readonly FIND_STOCK_RECENT_SEARCH_HISTORY = '/find-recent-search-history';

  //stock transaction
  public static readonly SUBMIT_NEW_ORDER: string = '/new-order/create';
  public static readonly PRE_CHECK_SUBMIT_NEW_ORDER: string = '/precheck/new-order/validate';
  public static readonly PRE_CHECK_AMEND_ORDER: string = '/precheck/amend/validate';
  public static readonly AMEND_ORDER: string = '/amend';
  public static readonly CANCEL_ORDER: string = '/cancel';
  public static readonly PRE_CHECK_CANCEL_ORDER: string = '/precheck/cancel/validate';
  public static readonly CHECK_CASH_BALANCE: string = '/cash-balance/read';
  public static readonly GET_ORDER_TOTAL_CONSIDERATION: string = '/get-order-total-consideration';

  //key config
  public static readonly KEY_CONFIG: string = '/key-config';

  //order book
  public static readonly READ_ORDER_BOOK: string = '/protected/order-book/read';
  public static readonly READ_ORDER_HISTORY: string = '/protected/order-history/read';

  //portfolio
  public static readonly READ_PORTFOLIO: string = '/protected/portfolio/read'

  //stock investment objective
  public static readonly CREATE_INVESTMENT_OBJECTIVE: string = '/protected/investment-objective/create';
  public static readonly CREATE_INVESTMENT_OBJECTIVE_PENDING_ACCOUNT: string = '/investment-objective/create';

  //stock time and sales
  public static readonly TIME_AND_SALES_READ: string = '/stock-data/protected/time-and-sales/read';

  // Report
  public static readonly FIND_STOCK_STATEMENT_MODEL_LIST = '/stock-statement-model-list/read';
  public static readonly GET_STOCK_STATEMENT_MODEL_STATEMENT = '/stock-statement-model/read';
  public static readonly GET_W8_BEN_FORM_DETAILS = '/stock-w8-ben-form/read';
  public static readonly UPDATE_W8_BEN_FORM_DETAILS = '/stock-w8-ben-form/update';
  public static readonly GENERATE_W8_BEN_FORM = '/stock-w8-ben-form/generate';

  //Watchlist
  public static readonly FIND_WATCHLIST_BY_ACCOUNT_ID = '/find-watchlist-by-account-id';
  public static readonly FIND_LAST_VIEW_WATCHLIST = '/find-last-view-watchlist';
  public static readonly UPDATE_WATCHLIST_SEQ = '/update-watchlist-seq';
  public static readonly UPDATE_LAST_VIEW = '/update-last-view';
  public static readonly UPDATE_WATCHLIST_NAME_LIST = '/update-watchlist-name-list';
  public static readonly FIND_ALL_WATCHLIST_ITEM_BY_LIST_ID = '/find-all-watchlist-item-by-list-id';
  public static readonly FIND_ALL_WATCHLIST_ITEM_COMPACT = '/find-all-watchlist-item-compact';
  public static readonly FIND_ALL_WATCHLIST_STOCK_BY_LIST_ID = '/find-all-watchlist-stock-by-list-id';
  public static readonly FIND_WATCHLIST_ITEM_BY_LIST_ID_PRODUCT_TYPE = '/find-watchlist-item-by-list-id-product-type';
  public static readonly IS_WATCHLIST_ITEM_EXIST = '/watchlist-item-exist';
  public static readonly ADD_WATCHLIST_ITEM = '/add-watchlist-item';
  public static readonly UPDATE_WATCHLIST_NAME_ITEM_SEQ = '/update-watchlist-name-item-seq';
  public static readonly DELETE_WATCHLIST_ITEM = '/delete-watchlist-item';
  public static readonly DELETE_WATCHLIST_ITEM_LIST = '/delete-watchlist-item-list';
  public static readonly UPDATE_WATCHLIST_ITEM_SEQ = '/update-watchlist-item-seq';
  public static readonly GET_COLUMN_SETTING = '/get-column-setting';
  public static readonly UPDATE_COLUMN_SETTING = '/update-column-setting';
  public static readonly FIND_BOND_EXPRESS_LIST = '/find-bond-express-list';
  public static readonly FIND_ALL_RECCOMEND_FUNDS = '/find-all-recommend-funds';
  public static readonly FIND_HIGHEST_DIVIDEND_YIELD_ALL_LIST = '/find-highest-dividend-yield-all-list';
  public static readonly FIND_HIGHEST_DIVIDENT_YIELD_FI_LIST = '/find-highest-dividend-yield-fi-list';
  public static readonly FIND_BEST_PERFORM_FUNDS = '/find-best-perform-funds';
  public static readonly FIND_TOP_VOLUME_FUNDS_FIXED_INCOME = '/find-top-volume-funds-fixed-income';
  public static readonly FIND_TOP_VOLUME_FUNDS_EQUITY_AND_OTHERS = '/find-top-volume-funds-equity-and-others';
  public static readonly FIND_BOND_EXPRESS_COMPACT_LIST = '/find-bond-express-compact-list';
  public static readonly FIND_RELATED_ARTICLES = '/find-related-articles';
  public static readonly FIND_SAVED_ARTICLES = '/find-saved-article';
  public static readonly ADD_SAVED_ARTICLE = '/add-saved-article';
  public static readonly DELETE_SAVED_ARTICLE = '/delete-saved-article';
  public static readonly FIND_DEFAULT_WATCHLIST_STOCK = '/find-default-watchlist-stock';
  public static readonly ADD_WATCHLIST_ITEM_IN_BULK = '/add-watchlist-item-in-bulk';

  // Alert
  public static readonly GET_ALERT_ROW = '/get-alert-row';
  public static readonly GET_TRIGGERED_ROW = '/get-triggered-row';
  public static readonly FIND_ALL_ACTIVE_ALERT_ITEM = '/find-all-active-alert-item';
  public static readonly FIND_ALL_ALERT_ITEM_TRIGGERED = '/find-all-alert-item-triggered';
  public static readonly DELETE_TRIGGERED = '/delete-triggered';
  public static readonly UPDATE_SEQ = '/update-seq';
  public static readonly FIND_LATEST_ASK_BID_PRICE = '/find-latest-ask-bid-price';
  public static readonly FIND_PRODUCT_DETAILS = '/find-product-details';


  // Report
  public static readonly FIND_MONTHLY_STATEMENT_LIST = '/monthly-statement-list/read';
  public static readonly FIND_UNIT_SPLIT_STATEMENT_LIST = '/unit-split-statement-list/read';
  public static readonly FIND_INCOME_DISTRIBUTION_LIST = '/income-distribution-list/read';
  public static readonly FIND_BOND_COUPON_STATEMENT_LIST = '/bond-coupon-statement-list/read';
  public static readonly GET_MONTHLY_STATEMENT = '/monthly-statement/read';
  public static readonly GET_UNIT_SPLIT_STATEMENT = '/unit-split-statement/read';
  public static readonly GET_INCOME_DISTRIBUTION_STATEMENT = '/income-distribution-statement/read';
  public static readonly GET_BOND_COUPON_STATEMENT = '/bond-coupon-statement/read';


  // RSP
  public static readonly RSP_DETAILS = '/rsp-details';
  public static readonly AVAILABLE_RSP_LIST = '/available-rsp-list';
  public static readonly AMOUNT = '/amount';

  // DDA
  public static readonly EMANDATE_LIST = '/emandate-bank-list';
  public static readonly NEW_EMANDATE_DATA_REQUIRED = '/new-emandate-data-required';
  public static readonly DDA_EMANDATE = '/dda-emandate';
  public static readonly DDA_FPX_LIST = '/dda-fpx-list';
  public static readonly EMANDATE_AMENDMENT = '/emandate-data-required-by-type';
  public static readonly EMANDATE_BANK_LIST: string = '/emandate-bank-list';

  //Product Price Alert
  public static readonly FIND_PRODUCT_PRICE_ALERT_BY_PRODUCT_TYPE = '/find-product-price-alert-by-product-type';
  public static readonly FIND_PRODUCT_PRICE_ALERT_BY_PRODUCT_CODE_PRODUCT_TYPE = '/find-product-price-alert-by-product-code-product-type';
  public static readonly FIND_PRODUCT_PRICE_ALERT_BY_PRODUCT_TYPE_STATUS = '/find-product-price-alert-by-product-type-status';
  public static readonly FIND_PRODUCT_PRICE_ALERT_BY_PRODUCT_TYPE_PRODUCT_CODE_STATUS = '/find-product-price-alert-by-product-code-product-type-status';

  // Referral Partnership
  public static readonly REFERRAL_PARTNERSHIP: string = '/referral-partnership';
  public static readonly PARTNERS: string = '/partners';
  public static readonly AFFILIATE_TRAFFIC: string = '/affiliate-traffic';

  //webcast
  public static readonly FIND_WEBCAST_LIST: string = '/find-all-webcast-list';
	public static readonly FIND_LATEST_VIDEO_LIST: string = '/find-latest-video-list';
	public static readonly FIND_MOST_VIEWED_VIDEO_LIST: string = '/find-most-viewed-video-list';
	public static readonly FIND_VIDEO_BY_CATEGORY_SUB_HEADER_ID = '/find-video-by-category-sub-header-id';
	public static readonly FIND_VIDEO_BY_TAGS: string = '/find-video-by-tags';
	public static readonly FIND_SUB_HEADER_NAME = '/find-sub-header-name';
	public static readonly GET_VIDEO_DETAILS = '/get-video-details';

  //events
  public static readonly GET_EVENT_BANNER_LIST = '/get-event-banner-list';

  //cash-account
  public static readonly CASH_ACCOUNT: string = '/cash-account';
  public static readonly FIND_ALL_INTEREST_RATE_LIST = '/find-all-interest-rate';
  public static readonly FIND_ALL_FX_RATE_LIST = '/find-all-fx-rate';
  public static readonly GET_CONSOLIDATE_ICASH_VIEW_BY_REFNO = '/get-consolidate-icash-view-by-refno';
  public static readonly GET_ALL_ICASHSUMMARY_LIST = '/get-all-icashSummary-list';
  public static readonly GET_ACC_BALANCE_SUMMAR = '/get-account-balance-summary';
  public static readonly IS_ACCOUNT_BALANCE_NEGATIVE = '/is-account-balance-negative';
  public static readonly GET_ALL_ACTIVE_IAMTRUST_ACCT_LIST =  '/get-all-active-iamTrustAcct-list';
  public static readonly LATEST_IAM_TRUST_BANK_RATE =  '/get-latest-iamTrustBankRate';
  public static readonly FIND_FX_FAVOURITE =  '/find-fx-favorite';
  public static readonly CREATE_UPDATE_FX_FAVORITE =  '/create-update-fx-favorite';
  public static readonly REMOVE_FX_FAVORITE =  '/remove-fx-favorite';


  //fee
  public static readonly FEE_HK_STOCK = '/get-hk-stock-fees';
  public static readonly FEE_US_STOCK = '/get-us-stock-fees';
  public static readonly FEE_BY_EXCHANGE = '/get-stock-fees-by-exchange';
  public static readonly FEE_ALL_CUR_BY_EXCHANGE = '/get-all-currencies-default-stock-fee-details-by-exchange';
  public static readonly GET_BOND_FEES_DETAILS = '/get-bond-fees-details';
  public static readonly GET_FUND_FEES = '/get-fund-fees';

  //Support
  public static readonly FIND_USER_GUIDE_LIST = '/find-all-guide-list';
  public static readonly FIND_MAKING_PAYMENTS_HTML_LIST = '/find-all-making-payment-html-list';
  public static readonly GET_LIVE_CHAT_SERVICE = '/get-live-chat-service';
  public static readonly CHAT_BOT_INITIATE_CONVERSATION = '/chat-bot-initiate-conversation';
  public static readonly TALK_TO_CHAT_BOT = '/talk-to-chat-bot';
  public static readonly CHAT_BOT_SEND_FEEDBACK = '/chat-bot-feedback';
  public static readonly SUBSCRIBE_ENEWS_PUBLIC = '/public-subscribe-enews';
  public static readonly REMOVE_PUBLIC_SUBSCRIPTION = '/remove-public-subscription';
	public static readonly IS_BUSINESS_HOUR = '/is-business-hour';

	//Notification
	public static readonly NOTIFICATION = '/notification'
	public static readonly FIND_NOTIFICATIONS = '/find-notifications';

	// Statement
    public static readonly STATEMENT = "/statement";

  public static readonly ABOUT_US_MILESTONE = '/about-us-milestone';

  //Module
  public static readonly IS_MODULE_ENABLED = '/is-module-enabled';

  //DDA
  public static readonly SAVE_NEW_DDA = '/save-new-dda';

  //EDDA
  public static readonly FIND_EDDA = '/find-edda';
  public static readonly IS_EDDA_EXIST = '/is-edda-exist';
  public static readonly FIND_ACTIVE_EDDA = '/find-active-edda';
  public static readonly CREATE_EDDA = '/create-edda';
  public static readonly CREATE_EDDA_RESEND_OTP = '/create-edda-resend-otp';
  public static readonly CREATE_EDDA_OTP_CONFIRMATION = '/create-edda-otp-confirmation';
  public static readonly REMOVE_EDDA = '/remove-edda'; 
  public static readonly CREATE_EDDI = '/create-eddi';
  public static readonly CREATE_TRANSACTION_EDDI = '/create-transaction-eddi';
  public static readonly GET_TRANSACTION_EDDI_STATUS = '/get-transaction-eddi-status';
  public static readonly GET_EDDA_STATUS = '/get-edda-status';
  public static readonly VERIFY_CLIENT_HKD_SUFFICIENT_AMOUNT = '/verify-client-hkd-sufficient-amount';
  public static readonly GET_EDDA_STATUS_WITH_MODULE = '/get-edda-status-with-module';
  public static readonly CREATE_STOCK_EDDI_TRANSACTION_LOG = '/create-stock-eddi-transaction-log';

   //DPMS Transaction
   public static readonly GET_AVAILABLE_PORTFOLIO_LIST = '/get-available-portfolio-list';
   public static readonly GET_DPMS_ID_LIST = '/get-dpms-portfolio-id-list';
   public static readonly GET_PORTFOLIO_PRODUCT_BUY_DISPLAY = '/get-portfolio-product-buy-display';
   public static readonly GET_PORTFOLIO_SUBSCRIPTION_DATE = '/get-portfolio-susbcription-date';
   public static readonly GET_PORTFOLIO_INVEST_AMOUNT_FX = '/get-portfolio-invest-amount-fx';
   public static readonly GET_PORTFOLIO_HOLDING_DISPLAY = '/get-portfolio-holding-display';
   public static readonly SUBMIT_PORTFOLIO_SUBSCRIPTION = '/submit-portfolio-subscription';
   public static readonly GET_PORTFOLIO_REDEMPTION_METHOD = '/get-portfolio-redemption-method';
   public static readonly SUBMIT_PORTFOLIO_TERMINATION = '/submit-portfolio-termination';
   public static readonly UPDATE_PORTFOLIO_RSP_AMOUNT = '/update-portfolio-rsp-amount';
   public static readonly UPDATE_DPMS_RSP_SUSPEND = '/update-dpms-rsp-suspend';
   public static readonly UPDATE_DPMS_RSP_RESUME = '/update-dpms-rsp-resume';
   public static readonly UPDATE_DPMS_RSP_DDA = '/update-dpms-rsp-dda';
   public static readonly PORTFOLIO_PARTIAL_REDEMPTION_PRECHECK = '/portfolio-partial-redemption-precheck';
   public static readonly SUBMIT_PORTFOLIO_PARTIAL_REDEMPTION = '/submit-portfolio-partial-redemption';
   public static readonly GET_ACCOUNT_PORTFOLIO_PERFORMANCE = '/get-account-portfolio-performance';
   public static readonly GET_PORTFOLIO_HOLDING_ASSET_ALLOCATION = '/get-portfolio-holding-asset-allocation';
   public static readonly FIND_ALL_PORTFOLIO_HOLDING_BY_ID = '/find-all-portfolio-holding-by-id';
   public static readonly FIND_SUBSCRIBED_PORTFOLIO_MONTHLY_FACTSHEET = '/find-subscribed-portfolio-monthly-factsheet';

   //Cash Transaction
   public static readonly CASH = '/cash';
   public static readonly CREATE_FPS_REQUEST = '/create-fps-request';
   public static readonly REGENERATE_FPS_REQUEST = '/regenerate-fps-request';
   public static readonly GET_FPS_REQUEST_BY_REFNO = '/get-fps-request-by-refno';
   public static readonly GET_FPS_DETAILS_BY_REQUEST_ID = '/get-fps-details-by-request-id';
   public static readonly UPLOAD_PAYMENT_PROOF = '/upload-payment-proof';
   public static readonly FIND_ALL_TRUST_CURRENCY = '/find-all-trust-currency';
   public static readonly REQUEST_CASH_WITHDRAWAL = '/request-cash-withdrawal';
   public static readonly CREATE_CASH_TRANSFER = '/create-cash-transfer';
   public static readonly GET_ALL_FX_HASHED_BY_SELL_AND_BUY_CURRENCY = '/get-all-fx-hashed-by-sell-and-buy-currency';
   public static readonly REQUEST_CASH_FX_CONVERSION = '/request-cash-fx-conversion';

   //Stock Transaction
   public static readonly GET_TICKET_INFO_BY_EXCHANGE_AND_CODE = '/get-ticker-info-by-exchange-and-code';
   public static readonly CLIENT_CASH_BALANCE = '/client-cash-balance';
   public static readonly VIEW_STOCK_HOLDINGS = '/view-stock-holdings';
   public static readonly GET_STOCK_QUOTE = '/get-stock-quote'; //can remove i think
   public static readonly CLIENT_PORTFOLIO_US = '/client-portfolio-by-login-acct';
   public static readonly GET_LS_TOKEN = '/get-ls-token';
   public static readonly CHECK_ERROR_MESSAGE = '/check-error-message';
   public static readonly VERIFY_US_LIVE_PRICE_SUBSCRIPTION = '/verify-us-live-price-subscription';
   public static readonly STOCK_AVAILABLE_ORDER_TYPE = '/stock-available-order-type';
   public static readonly US_PRE_TRADE_CHECKING = '/us-pre-trade-checking';
   public static readonly PRE_TRADE_CHECKING = '/pre-trade-checking';
   public static readonly CONFIRM_PLACE_ORDER = '/confirm-place-order';
   public static readonly STOCK_AVAILABLE_SELL_QUANTITY = '/stock-available-sell-quantity-by-refno-stock-code';
   public static readonly SHOW_ORDER_BOOK_LIST = '/show-order-book-list';
   public static readonly ORDER_DETAIL = '/order-detail';
   public static readonly CLIENT_CASH_BALANCE_GROUP_BY_MARKET = '/client-cash-balance-group-by-market';
   public static readonly SUBSCRIBE_US_LIVE_PRICE = '/subscribe-us-live-price';
   public static readonly US_LIVE_PRICE_TNC_ACCEPT_LATER = '/us-live-price-tnc-accept-later';
   public static readonly CHECK_W8_BEN_ADDRESS_VALIDITY = '/check-w8-ben-address-validity';
   public static readonly GET_NEW_ORDER_FEE = '/get-new-order-fee';
   public static readonly GET_US_NEW_ORDER_FEE = '/get-us-new-order-fee';

  //best-worst-performer
  public static readonly BEST_WORST_PERFORMER: string = '/best-worst-performer';
  public static readonly FIND_TOP_VOL_FUNDS: string = '/find-top-vol-funds';
  public static readonly FIND_TOP_VOL_BOND: string = '/find-top-vol-bond';
  public static readonly FIND_TOP_BOND_PERFORMANCE: string = '/find-top-bond-performance';
  public static readonly FIND_TOP_ETF_PERFORMANCE: string = '/find-top-etf-performance';
  public static readonly FIND_BEST_WORST_PERFORM_FUNDS: string = '/find-best-worst-perform-funds';
  public static readonly FIND_CURRENT_LOCALE_DATE: string = '/find-current-locale-date';

  //us-treasury-yield
  public static readonly FIND_NEAREST_BOND_TO_TENOR: string = '/find-nearest-bond-to-tenor';

	//mmf-highest-yield
  public static readonly GET_ALL_CURRENCY_MMF_HIGHEST_YIELD: string = '/get-all-currency-mmf-highest-yield';
  
  //Holding
	public static readonly GET_ACCT_LIST_VIEW_HOLDING = '/get-all-account-list-for-view-holding-details';
  public static readonly GET_ACCOUNT_DETAIL_HOLDINGS = '/get-account-detail-holdings';
	public static readonly GET_HISTORICAL_TRANSACTION = '/get-account-transaction-history';
	public static readonly GET_PENDING_TRANSACTION = '/get-account-pending-transaction';
	public static readonly GET_PAYOUT_HISTORY = '/get-payout-history';
	public static readonly GET_ALL_ACCT_HOLDINGS_SUMMARY = '/get-account-holdings-summary';
	public static readonly GET_CLIENT_HOLDINGS_PERFORMANCE = '/get-client-holdings-performance';
	public static readonly GET_DPMS_PERFORMANCE_FOR_HOLD_COMPARE = '/get-dpms-performance-for-holdings-comparison';
	public static readonly GET_HOLD_ASSET_ALLOC = '/get-holdings-asset-allocation';
	public static readonly GET_HOLD_GEO_ALLOC = '/get-holdings-geo-allocation';
  public static readonly GET_ACCOUNT_DETAILS_FOR_VIEW_HOLDING_DETAILS = '/get-account-details-in-view-holding';
    public static readonly GET_ACCOUNT_BOND_HOLDINGS = '/get-account-bond-holdings';
	public static readonly GET_HOLDINGS_PROD_ALLOCATION = '/get-holdings-prod-allocation';
	public static readonly VOID_TRANSACTION = '/void-transaction';
	public static  readonly VOID_CASH_ACCOUNT_TRANSACTION = '/void-cash-transaction-request';
  public static  readonly GET_TIER_TOTAL_AUM = '/get-tier-total-aum';
  public static readonly GET_NEXT_TIER_TOTAL_AUM = '/get-next-tier-total-aum';
  public static readonly GET_ACCOUNT_PENDING_UNSETTLED_TRANSACTIONS = '/get-account-pending-unsettled-transactions';
	
  //forget-login-id
  public static readonly FORGOT_LOGIN_ID_CHECKING: string = '/forgot-login-id-checking';
  public static readonly REQUEST_SEND_OTP: string = '/request-send-sms-otp';
  public static readonly RECOVER_LOGIN_ID: string = '/recover-login-id';

  //forget-password
  public static readonly FORGOT_PASSWORD_CHECKING: string = '/forgot-password-checking';
  public static readonly REQUEST_OTP_PASSWORD: string = '/request-send-one-time-password';
  public static readonly RESET_PASSWORD_SUBMIT: string = '/reset-password-submit';

  //corp-first-login
  public static readonly CORP_FIRST_LOGIN_CHECKING: string = '/corp-first-login-checking';
  public static readonly CORP_FIRST_LOGIN_OTP: string = '/corp-first-login-otp';
  public static readonly CORP_FIRST_LOGIN_SUBMIT: string = '/corp-first-login-submit';

		// account-update
  public static readonly SUBMIT_PI_DECLARATION: string = '/pi-declaration-submit';
	public static readonly GET_ACCOUNT_UPDATE_DETAILS: string = '/get-account-update-details';

	//stock activated
	public static readonly IS_MAIN_ACCOUNT_ACCEPT_TC: string = '/is-main-account-accept-tc';
	public static readonly IS_ACCEPT_TC: string = '/is-accept-tc';
	public static readonly ACCEPT_TC: string = '/accept-tc';
    public static readonly ACCEPT_All_ACCT_TC: string = '/accept-all-acct-stock-tc';
	public static readonly CREATE_BCAN: string = '/create-bcan';
  public static readonly DEACTIVATE_CN_STOCK_SERVICE = '/deactivate-cn-stock-service';
  public static readonly UPDATE_SG_STOCK_CONSENT: string = '/update-sg-stock-consent';
	public static readonly IS_SG_NOT_AVAILABLE_ACCOUNT: string = '/is-sg-not-available-acct';
	public static readonly GET_SG_STOCK_CONSENT: string = '/get-sg-stock-consent';
	public static readonly UPDATE_US_LIVE_DATA_PRO_USER = '/update-us-live-data-professional-user';
	public static readonly FIND_BCAN_ACTIVE_ACCOUNT = '/find-bcan-active-account-list';
	
	public static readonly GET_IAM_ACCOUNT_BY_REFNO: string = '/get-iam-account-by-refno';
	public static readonly VIEW_VALUATION_REPORT: string = '/view-valuation-report';
	
	public static readonly CHECK_PASSWORD: string ='/check-password';
    public static readonly GET_ACCOUNT_PROFIT_AND_LOSS_SUMMARY: string = '/get-account-profit-loss-summary';
    public static readonly GET_FUND_INVESTMENT_LIST: string = '/get-fund-investment-list';
    public static readonly GET_FUND_WITHDRAWAL_LIST: string = '/get-fund-withdrawal-list';
    public static readonly GET_FUND_DIVIDEND_LIST: string = '/get-fund-dividend-list';
    public static readonly GET_FUND_SWITCH_LIST: string = '/get-fund-switch-list';
    public static readonly FIND_FUND_PENDING_BUY_TRANSACTIONS_FOR_VOID = '/find-fund-pending-buy-transactions-for-void';
    public static readonly FIND_FUND_PENDING_SELL_TRANSACTIONS_FOR_VOID = '/find-fund-pending-sell-transactions-for-void';
    public static readonly GET_BOND_INVESTMENT_LIST: string = '/get-bond-investment-list';
    public static readonly GET_BOND_WITHDRAWAL_LIST: string = '/get-bond-withdrawal-list';
    public static readonly GET_BOND_DIVIDEND_LIST: string = '/get-bond-dividend-list';
    public static readonly GET_STOCK_INVESTMENT_LIST: string = '/get-stock-investment-list';
    public static readonly GET_STOCK_WITHDRAWAL_LIST: string = '/get-stock-withdrawal-list';
    public static readonly GET_STOCK_DIVIDEND_LIST: string = '/get-stock-dividend-list';
    public static readonly GET_ETF_INVESTMENT_LIST: string = '/get-etf-investment-list';
    public static readonly GET_ETF_WITHDRAWAL_LIST: string = '/get-etf-withdrawal-list';
    public static readonly GET_ETF_DIVIDEND_LIST: string = '/get-etf-dividend-list';
    public static readonly FIND_US_ETF_PENDING: string = '/find-us-etf-pending';
    public static readonly GET_PORTFOLIO_PNL_LIST: string = '/get-portfolio-pnl-list';

    //rcs margin
    public static readonly MARGIN: string = '/margin';
    public static readonly FIND_PRODUCT_LTV_INFO: string = '/find-product-ltv-info';
    public static readonly MARGIN_INTEREST_RATE: string = '/margin-interest-rate';
    public static readonly FIND_ORDER_CREDIT_BALANCE: string = '/find-order-credit-balance';
    public static readonly MARGIN_SUMMARY: string = '/margin-summary';
    public static readonly HAS_OUTSTANDING_LOAN: string = '/has-outstanding-loan';
    public static readonly MARGIN_TRUST_ACCT_PRE_CHECK: string = '/margin-trust-acct-pre-check'; 
    public static readonly MARGIN_ORDER_BULK_PRE_CHECK: string = '/margin-order-bulk-pre-check';
    public static readonly FIND_MARGIN_LOAN_HISTORY: string = '/find-margin-loan-history';
    public static readonly FIND_MARGIN_CURRENCIES: string = '/find-margin-currencies';
    public static readonly FIND_MARGIN_LOAN_AND_REPAYMENT_SUMMARY: string = '/find-margin-loan-and-repayment-summary';
    public static readonly FIND_MARGIN_LOAN_REPAYMENT_DETAILS: string = '/find-margin-loan-repayment-details';
    public static readonly MARGIN_CREDIT_BALANCE: string = '/margin-credit-balance';
    public static readonly GET_MARGIN_ELIGIBILITY_DETAIL = '/get-margin-eligibility-detail';
    public static readonly FIND_CREDIT_SUMMARY = '/find-credit-summary';
    public static readonly FIND_MARGIN_ELIGIBLE_ACCOUNT_LIST_BY_ORDER = '/find-margin-eligible-account-list-by-order';
    public static readonly MARGIN_OPT_IN = '/margin-opt-in';
    public static readonly MARGIN_OPT_OUT = '/margin-opt-out';
    public static readonly CHECK_CLIENT_FACING_MARGIN_CALL = '/check-client-facing-margin-call';
    public static readonly MARGIN_PORTFOLIO = '/margin-portfolio';
    public static readonly HAS_REGIONAL_MARGIN_ACCOUNT = '/has-regional-margin-account';
    public static readonly GET_MARGIN_CALL_ALERT_SETUP = '/get-margin-call-alert-setup';
    public static readonly UPDATE_MARGIN_CALL_ALERT_SETUP = '/update-margin-call-alert-setup';
    public static readonly GET_MARGIN_SYSTEM_INTEREST_RATE = '/margin-system-interest-rate';
    public static readonly GET_ACCOUNT_RPQ: string = '/get-account-rpq';
    public static readonly FIND_ALL_PRODUCT_TYPE_LTV_INFO = '/find-all-product-type-ltv-info';
    public static readonly FIND_DAILY_PRODUCT_TYPE_LTV_INFO = '/find-daily-product-type-ltv-info';
    public static readonly UPDATE_MARGIN_CREDIT_LIMIT = '/update-margin-credit-limit';
    public static readonly ACCOUNT_RESYNC = '/account-resync';

		//virtual asset knowledge
		public static readonly HAS_VIRTUAL_ASSET_KNOWLEDGE = '/has-virtual-asset-knowledge';
	
	// auto-sweep
	public static readonly AUTO_SWEEP = '/auto-sweep';
	public static readonly GET_ACCOUNT_AUTO_SWEEP_DETAILS_LIST = '/get-account-auto-sweep-details-list';
	public static readonly CREATE_OPT_IN = '/create-opt-in';
	public static readonly CREATE_OPT_OUT = '/create-opt-out';
	public static readonly FIND_SPECIFIC_AS_ACCOUNT_HOLDING_VALUATION = '/find-specific-as-account-holding-valuation';
	public static readonly FIND_AS_ACCOUNT_HOLDING_VALUATION = '/find-as-account-holding-valuation';
	public static readonly GET_AS_ANNUALISED_RETURNS = '/get-as-annualised-returns';
	public static readonly FIND_AS_PORTFOLIO_FACTSHEET = '/find-as-portfolio-factsheet';
	public static readonly FIND_AS_PORTFOLIO_INFO = '/find-as-portfolio-info';
	public static readonly GET_IS_REFNO_AS_CURRENCY_ACTIVE = '/get-is-refno-as-currency-active';
	public static readonly GET_AS_PORTFOLIO_LOC_LIST = '/get-as-portfolio-loc-list';
	public static readonly FIND_AS_HOLDINGS_DETAILS = '/find-as-holdings-details';
	public static readonly FIND_AUTO_SWEEP_FAQ = '/find-auto-sweep-faq';
	public static readonly HAS_AUTO_SWEEP_ACCOUNT = '/has-auto-sweep-account';
	public static readonly FIND_FHK_AUTO_SWEEP_ACCOUNT = '/find-fhk-auto-sweep-account';
	public static readonly VIEW_AUTO_SWEEP_VALUATION_REPORT = '/view-auto-sweep-valuation-report';
	
	// invoice 
	public static readonly GET_ALL_INVOICE = '/get-all-invoice';
}
